var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tabs',{staticClass:"tabs_custom",model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title":_vm.$t('general_info')}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"form_custom"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('name_q'),"vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:errors.length > 0 ? 'text-danger' : '',attrs:{"for":"time"}},[_vm._v(_vm._s(_vm.$t('name_q'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false:null},model:{value:(_vm.frm.name),callback:function ($$v) {_vm.$set(_vm.frm, "name", $$v)},expression:"frm.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('time'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:errors.length > 0 ? 'text-danger' : '',attrs:{"for":"title"}},[_vm._v(_vm._s(_vm.$t('time'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('b-form-input',{attrs:{"id":"title","type":"number","state":errors.length > 0 ? false:null,"placeholder":"Nhập số phút"},model:{value:(_vm.frm.time),callback:function ($$v) {_vm.$set(_vm.frm, "time", $$v)},expression:"frm.time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('level'),"vid":"level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{class:errors.length > 0 ? 'text-danger': null,attrs:{"for":"level"}},[_vm._v(_vm._s(_vm.$t('level'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('v-select',{attrs:{"id":"level","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.levelData,"reduce":function (label) { return label.id; },"state":errors.length > 0 ? false:null},model:{value:(_vm.frm.level),callback:function ($$v) {_vm.$set(_vm.frm, "level", $$v)},expression:"frm.level"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('type'),"vid":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{class:errors.length > 0 ? 'text-danger': null,attrs:{"for":"type"}},[_vm._v(_vm._s(_vm.$t('type'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('v-select',{attrs:{"id":"type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"label","options":_vm.typeData,"reduce":function (label) { return label.value; },"state":errors.length > 0 ? false:null},model:{value:(_vm.frm.type),callback:function ($$v) {_vm.$set(_vm.frm, "type", $$v)},expression:"frm.type"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[(_vm.frm.type === 1)?_c('validation-provider',{attrs:{"name":_vm.$t('pass_lesson'),"vid":"type","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{class:errors.length > 0 ? 'text-danger' : '',attrs:{"for":"title"}},[_vm._v(_vm._s(_vm.$t('pass_lesson'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('b-form-input',{attrs:{"id":"pass_lesson","type":"number","placeholder":"Nhập số điểm qua bài"},model:{value:(_vm.frm.pass_lesson),callback:function ($$v) {_vm.$set(_vm.frm, "pass_lesson", $$v)},expression:"frm.pass_lesson"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,827026284)}):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"status"}},[_vm._v(_vm._s(_vm.$t('status')))]),_c('b-form-checkbox',{staticClass:"custom-control-danger",attrs:{"id":"status","name":"check-button","switch":""},model:{value:(_vm.frm.status),callback:function ($$v) {_vm.$set(_vm.frm, "status", $$v)},expression:"frm.status"}})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"btn btn-sm float-right",attrs:{"variant":"danger","type":"submit","disabled":_vm.tabIndex !== 0},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('feather-icon',{attrs:{"size":"15","icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t("save_info"))+" ")],1)],1)],1)],1)],1)],1),_c('b-tab',{attrs:{"title":_vm.$t('content_exam'),"disabled":!_vm.frm.id}},[(_vm.frm.type === 1)?_c('exercise-edit',{attrs:{"rows":_vm.questionGroups,"exercise-id":parseInt(_vm.frm.id),"total-point":_vm.totalPoint,"pass-score":parseInt(_vm.frm.pass_lesson),"list-q":_vm.listQ},on:{"update-total-point":_vm.updateTotalPoint}}):[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptions,"limit":10,"input-props":_vm.inputProps},on:{"selected":_vm.onSelected,"input":_vm.onInputChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}])})],1)],1),(_vm.isLoading)?_c('b-skeleton-wrapper',{attrs:{"loading":true},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-card',[_c('b-skeleton',{attrs:{"width":"85%"}}),_c('b-skeleton',{attrs:{"width":"55%"}}),_c('b-skeleton',{attrs:{"width":"70%"}})],1)]},proxy:true}],null,false,2022486804)}):[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-list-group',{staticClass:"list_group_custom list_group_custom-exercise",attrs:{"flush":""}},[_c('draggable',{staticClass:"list-group list-group-flush cursor-move",attrs:{"tag":""},model:{value:(_vm.skillData),callback:function ($$v) {_vm.skillData=$$v},expression:"skillData"}},_vm._l((_vm.skillData),function(item,index){return _c('b-list-group-item',{key:index,staticClass:"align-items-center",attrs:{"id":item.id}},[_c('skill',{attrs:{"index":index,"item":item,"list-q":_vm.listQ,"total-point":_vm.totalPoint,"type":_vm.frm.type,"tag-options":_vm.tagOptions,"tag-option-by-review":_vm.tagOptionByReview},on:{"deleteSkill":_vm.deleteSkill,"pushSkill":_vm.pushSkill}})],1)}),1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"btn btn-sm float-right",attrs:{"variant":"danger","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.compilation($event)}}},[_c('feather-icon',{attrs:{"size":"15","icon":"SaveIcon"}}),_vm._v(" Lưu thông tin bài thi ")],1)],1)],1)]]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }