<template>
  <b-overlay
    :show="isLoading"
    :opacity="0.6"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <div class="item_content_row">
      <b-row>
        <b-col
          sm="10"
        >
          <validation-observer ref="updateName">
            <div
              class="item_exercises"
              :class="isShow ? 'active' : ''"
            >
              <template v-if="!item.edit">
                <span
                  ref="showListQuestion"
                  class="heading_name"
                  @click="editable"
                  v-html="item.name"
                />
              </template>
              <template v-else>
                <b-row>
                  <b-col sm="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Tên nhóm câu hỏi"
                      vid="name"
                      rules="required"
                      mode="passive"
                    >
                      <quill-editor
                        v-model="frmEditQr.name"
                        type="text"
                        required
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col md="12">
                    <upload-media
                      class="btn_upload_custom"
                      :image="frmEditQr.images"
                      :audio="frmEditQr.audio"
                      :choose="5"
                      @uploadMedia="uploadMedia"
                    />
                  </b-col>
                </b-row>
                <b-col
                  md="12"
                  class="mb-2"
                >
                  <b-button
                    class="float-right btn btn-sm"
                    variant="danger"
                    :disabled="isLoading"
                    style="margin: 10px 0 0 15px; min-width: 76px;"
                    @click="handleUpdate"
                  >
                    Cập nhật
                  </b-button>
                </b-col>
              </template>
            </div>
          </validation-observer>
        </b-col>
        <b-col sm="2">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon rounded-circle float-right"
            style="margin-left: 5px"
            @click="deleteQuestionGroup(item.id, indexQuestionGroup)"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon rounded-circle float-right"
            style="margin-left: 5px"
            @click="toggleEdit"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="rounded-circle float-right button-add"
            dropleft
          >
            <template #button-content>
              <feather-icon icon="PlusIcon" />
            </template>
            <b-dropdown-item @click="modalQuestionType = true">
              <feather-icon icon="PlusIcon" />
              Thêm câu hỏi
            </b-dropdown-item>
            <b-dropdown-item
              @click="editLibraryQuestion()"
            >
              <feather-icon icon="DatabaseIcon" />
              Thư viện câu hỏi
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-button
          class="button_open_exercise"
          variant="outline-secondary"
          @click="editable"
        >
          <feather-icon :icon="isShow ? 'ChevronDownIcon' : 'ChevronRightIcon'" />
        </b-button>
      </b-row>
    </div>
    <div v-show="isShow">
      <template v-if="rows.length">
        <b-list-group class="list-group-child-exercise">
          <draggable
            v-model="rows"
            tag="tbody"
            class="list-group list-group-flush cursor-move"
            @end="value => onEnd(value)"
          >
            <b-list-group-item
              v-for="(question, index) in rows"
              :key="index"
              class="align-items-center"
              variant=""
            >
              <div class="item_content_row__exercise">
                <b-row>
                  <b-col cols="8">
                    <div
                      class="demo-inline-spacing ml-3"
                      style="flex-wrap: nowrap;"
                    >
                      <b>
                        {{ index + 1 }}
                      </b>
                      <router-link :to="{ name: typeQuestion.find(val => val.type === question.type).to, params: { data: question } }">
                        <template v-if="question.type === 4">
                          <span
                            v-for="q in JSON.parse(question.name)"
                            style="display: flex"
                            v-html="q.q + ' <strong>(==)</strong> ' + q.ans"
                          />
                        </template>
                        <template v-else-if="question.type === 3">
                          <span
                            v-for="q in JSON.parse(question.name)"
                            v-html="q.q + ' <strong>,</strong> '"
                          />
                        </template>
                        <span
                          v-else
                          v-html="question.name"
                        />

                      </router-link>
                    </div>
                  </b-col>
                  <b-col cols="3">
                    <b-badge variant="light-warning float-right">
                      {{ typeQuestion.find(val => val.type === question.type) ? typeQuestion.find(val => val.type === question.type).text : '' }}
                    </b-badge>
                    <b-badge variant="light-success float-right">
                      {{ question.point }} điểm
                    </b-badge>
                  </b-col>
                  <b-col cols="1">
                    <div class="setting_exam">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-secondary"
                        style="margin-left: 8px"
                        class="btn-icon rounded-circle float-right"
                        @click="deleteQuestion(index, question.pivot.id)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-secondary"
                        class="btn-icon rounded-circle float-right"
                        @click="showModalPoint(question)"
                      >
                        <feather-icon icon="MousePointerIcon" />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>

            </b-list-group-item>
          </draggable>
        </b-list-group>
      </template>
      <template v-else>
        <b-badge
          href="#"
          class="d-block"
          pill
          variant="danger"
        >
          Chưa có câu hỏi nào
        </b-badge>
      </template>
    </div>
    <b-modal
      id="modal-edit"
      v-model="modalShowEditPoint"
      ok-variant="danger"
      size="md"
      ok-title="Áp dụng"
      centered
      hide-footer
      hide-header
    >
      <b-form-group>
        <label>Điểm</label>
        <b-form-input
          id="title"
          v-model="questionEdit.point"
          type="number"
        />
      </b-form-group>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="danger"
        class="btn-sm float-right"
        @click="updatePointQuestion"
      >
        <feather-icon
          size="15"
          icon="SaveIcon"
        />
        Cập nhật điểm
      </b-button>
    </b-modal>
    <b-modal
      id="modal-edit"
      v-model="modalQuestionType"
      ok-variant="danger"
      hide-footer
      centered
      title="Chọn loại câu hỏi"
    >
      <b-row>
        <b-col
          v-for="(q) in typeQuestion"
          md="4"
          style="margin-bottom: 20px;"
        >
          <div class="demo-inline-spacing">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              target="_blank"
              :to="{ name: q.to}"
              style="border: 1px dashed #d7d7d7 !important;"
            >
              <feather-icon
                v-if="q.icon"
                :icon="q.icon"
                class="mr-50"
              />
              {{ q.text }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="modal-edit"
      v-model="modalShowQuestion"
      ok-variant="danger"
      :title="$t('b_q')"
      hide-footer
      centered
      size="xl"
    >
      <question-library
        :choose="true"
        :list-q="listQ"
        @listQuestionPart="listQuestionGroup"
      />

    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BImg, BFormTextarea, BEmbed, BDropdown, BDropdownItem, BFormInput, BOverlay, BButton, BFormGroup, BInputGroup, BFormRadio, BInputGroupAppend, BBadge, BFormCheckbox, BFormFile, BCardText, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import { required } from '@validations'
import draggable from 'vuedraggable'
import config from '@/config'
import QuestionDetail from '@/views/capacity/exams/QuestionDetail'
import uploadMedia from '@/views/library/UploadMedia'
import questionLibrary from '@/views/capacity/QuestionLibrary'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'

export default {
  components: {
    BRow,
    questionLibrary,
    AppCollapse,
    AppCollapseItem,
    uploadMedia,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BButton,
    BImg,
    BEmbed,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    quillEditor,
    vSelect,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    BFormRadio,
    BInputGroupAppend,
    BBadge,
    BFormCheckbox,
    BFormFile,
    BCardText,
    QuestionDetail,
    draggable,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    indexQuestionGroup: {
      type: Number,
      required: true,
    },
    totalPoint: {
      type: Number,
      required: true,
    },
    listQ: {
      required: false,
    },
  },
  data() {
    return {
      isShow: false,
      showAddAnswer: false,
      showAddQuestion: false,
      modalShowEditPoint: false,
      modalShowQuestion: false,
      modalShowNewQuestion: false,
      add_answer: null,
      snowOption: {
        theme: 'snow',
        placeholder: 'Nhập câu hỏi',
      },
      showDescription: false,
      modalQuestionType: false,
      isLoading: false,
      questionDetail: {
        name: null,
        type: null,
        answers: [],
        is_correct: false,
        image: null,
        audio: null,
      },
      frm: {
        name: null,
        tag: [],
        type: null,
        status: 1,
        answers: [],
      },
      typeQuestion: config.type_question,
      frmEditQr: {
        audio_id: null,
        images: [],
        audio: null,
        name: null,
      },
      tagOptions: [],
      questionGroup: {
        image: null,
        audio: null,
      },
      rows: [],
      questionEdit: {
        name: null,
        point: null,
        id: null,
      },
      imgUrl: null,
      audioUrl: null,
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      answerIndex: config.course.answerIndex,
      required,
    }
  },
  computed: {
    newAnswerIndex() {
      return this.answerIndex[this.frm.answers.length]
    },
  },
  created() {
    this.loadTags()
  },
  methods: {
    showModalPoint(question) {
      this.modalShowEditPoint = true
      this.questionEdit.name = question.name
      this.questionEdit.point = question.point
      this.questionEdit.id = question.pivot.id
    },
    uploadMedia(media) {
      this.frmEditQr.images = media.multipleImage
      this.frmEditQr.audio = media.audio
    },
    loadTags() {
      this.isLoading = true
      this.$http.get('/tags', {
        params: {
          type: 0,
        },
      })
        .then(res => {
          this.tagOptions = res.data.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    editLibraryQuestion() {
      this.modalShowQuestion = true
    },
    updatePointQuestion() {
      const formData = new FormData()
      formData.append('point', this.questionEdit.point)
      const url = `/question-groups/update-point/${this.questionEdit.id}`
      this.$http({
        method: 'post',
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.modalShowEditPoint = false
          this.loadQuestions()
          this.$bvToast.toast('Cập nhật điểm thành công', {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'success',
            solid: false,
          })
        })
      // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.$bvToast.toast('Cập nhật điểm thất bại', {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'danger',
            solid: false,
          })
        }).finally(() => {
          this.isLoading = false
        })
    },
    listQuestionGroup(listQuestions) {
      const ids = []
      let pt = 0
      // eslint-disable-next-line no-unused-vars
      let addPoint = 0
      listQuestions.map(v => {
        ids.push(v.id)
        pt = v.point
        addPoint += v.point
      })
      const formData = new FormData()
      formData.append('questionGroupId', this.item.id)
      formData.append('questionId', JSON.stringify(ids))
      formData.append('point', pt)
      const url = '/question-groups/add-question'
      this.$http({
        method: 'post',
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(res => {
          if (res.data) {
            const updatePoint = this.totalPoint + addPoint
            this.$emit('update-total-point', updatePoint)
            this.showToast('success', 'Thêm câu hỏi thành công')
            this.isShow = false
            this.$refs.showListQuestion.click()
            this.modalShowQuestion = false
          }
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    editable() {
      this.isShow = !this.isShow
      this.loadQuestions()
    },
    loadQuestions() {
      const params = {}

      params.question_group_id = this.item.id

      this.isLoading = true
      this.$http.get('/question-groups/list-question', {
        params,
      })
        .then(res => {
          const qu = res.data.questions
          this.rows = qu
          //
          this.rows.map(v => {
            // eslint-disable-next-line no-param-reassign
            v.point = 0
            if (v.pivot.point) {
              v.point = v.pivot.point
            }
            v.edit = false
            // eslint-disable-next-line no-param-reassign
            v.newName = v.name
            // eslint-disable-next-line no-param-reassign
            v.toggle = false
            return v
          })
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    onEnd(value) {
      if (value.oldIndex === value.newIndex) return
      const newOrder = this.rows[value.newIndex].pivot.order
      const oldOrder = this.rows[value.oldIndex].pivot.order
      if (newOrder === oldOrder) return
      if (value.newIndex < value.oldIndex) {
        this.changeOrder(newOrder, this.rows[value.newIndex + 1].pivot.order)
      } else {
        this.changeOrder(newOrder, this.rows[value.newIndex - 1].pivot.order)
      }
    },
    changeOrder(oldOrder, newOrder) {
      this.isLoading = true
      this.$http.put('question-groups/change-order-question', { oldOrder, newOrder, questionGroupId: this.item.id })
      // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.showToast('success', 'Cập nhật thứ tự thành công')
          // eslint-disable-next-line no-unused-vars
        }).catch(e => {
          this.showToast('danger', 'Cập nhật thứ tự thất bại')
        }).finally(() => {
          this.isLoading = false
        // this.loadItems()
        })
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Thông báo',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    deleteQuestion(index, id) {
      this.$swal({
        title: 'Bạn có chắc chắn xóa không ?',
        text: 'Bạn sẽ không thể lấy lại dữ liệu đã xóa',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK !',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          this.$http.delete(`/question-groups/delete-question/${id}`)
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.showToast('success', 'Xoá câu hỏi thành công')
              const updatePoint = this.totalPoint - this.rows[index].point
              this.rows.splice(index, 1)
              this.$emit('update-total-point', updatePoint)
            }).catch(e => {
              this.showToast('danger', 'Xoá  câu hỏi thất bại')
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    toggleEdit() {
      this.item.edit = !this.item.edit
      this.frmEditQr.name = this.item.name
      this.frmEditQr.images = this.item.images
      this.frmEditQr.audio = this.item.audio
    },
    showQuestionDetail(q) {
      this.questionDetail.name = q.name
      this.questionDetail.type = q.type
      this.questionDetail.answers = q.answers
      this.questionDetail.image = q.image ? q.image.url : null
      this.questionDetail.audio = q.audio ? q.audio.url : null
      if (q.answers) {
        q.answers.map((v, index) => {
          if (v.is_correct === 1) {
            this.questionDetail.is_correct = index
          }
        })
      }
      this.showDescription = true
    },
    deleteQuestionGroup(id, index) {
      this.$swal({
        title: 'Bạn có chắc không ?',
        text: 'Bạn sẽ không thể lấy lại dữ liệu đã xóa',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Chắc chắn !',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          this.$http.delete(`/question-groups/${id}`)
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.showToast('success', 'Xoá nhóm câu hỏi thành công')
              this.$emit('deleteQuestionGroup', index)
            }).catch(e => {
              this.showToast('danger', 'Xoá nhóm câu hỏi thất bại')
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    loadItem() {
      this.isLoading = true
      this.$http.get(`/question-groups/show/${this.item.id}`)
        .then(res => {
          const { data } = res
          this.item.name = data.name
          this.frmEditQr.images = data.images
          this.frmEditQr.audio = data.audio
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    handleUpdate(item) {
      this.$refs.updateName.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()
          if (this.frmEditQr.name) formData.append('name', this.frmEditQr.name)
          if (this.frmEditQr.images.length) {
            formData.append('images', JSON.stringify(this.frmEditQr.images))
          }
          if (this.frmEditQr.audio) {
            formData.append('audio_id', this.frmEditQr.audio.id)
          }
          if (this.item.id) formData.append('_method', 'PUT')

          this.$http({
            method: 'post',
            url: `/question-groups/${this.item.id}`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.loadItem()
              this.showToast('success', 'Cập nhật thành công')
            })
          // eslint-disable-next-line no-unused-vars
            .catch(e => {
              this.showToast('danger', 'Cập nhật thất bại')
            }).finally(() => {
              this.isLoading = false
              item.edit = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
.btn_remove_imgUpload{
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 1;
}

.btn_remove_imgUpload svg{
  width: 21px;
  color: #cfcfcf;
}
.btn_remove_imgUpload svg:hover{
  color: #000;
}
.border-checked-none .input-group-text{
  border: none;
  background-color: transparent;
}
.default-image{
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.btn-label {
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}
</style>
