<template>
  <b-overlay
    :show="isLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-row>
      <b-col md="4">
        <label for="">
          {{ $t("Search") }}
        </label>
        <b-form-input
          v-model="filter.key"
          :placeholder="$t('search_key')"
        />
      </b-col>
      <b-col md="3">
        <label for="">
          Loại câu hỏi
        </label>
        <b-form-select
          v-model="filter.type"
          :options="typeOptions"
        />
      </b-col>
      <b-col md="5">
        <label for="">
          Tag
        </label>
        <vue-autosuggest
          :suggestions="filteredOptions"
          :limit="30"
          :input-props="inputProps"
          @selected="onSelected"
          @input="onInputChange"
        >
          <template slot-scope="{suggestion}">
            <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        class="mt-2"
      >
        <b-list-group>

          <b-list-group-item
            v-for="(listItem, index) in listQuestions"
            :key="index"
            tag="li"
          >
            <div class="d-flex library-question justify-content-md-start align-items-center">
              <b-form-checkbox
                v-model="listQuestionGroups"
                :value="listItem"
                class="custom-control-danger"
                name="check-button"
              />
              <b-badge variant="light-primary">
                {{ typeQuestion.find(val => val.type === listItem.type).text }}
              </b-badge>
              <app-collapse class="detail-question">
                <template v-if="listItem.type ===4">
                  <span
                    v-for="q in JSON.parse(listItem.name)"
                    style="display: flex"
                    v-html="q.q + ' <strong>(==)</strong> ' + q.ans"
                  />
                </template>
                <template v-else-if="listItem.type === 3">
                  <span
                    v-for="q in JSON.parse(listItem.name)"
                    v-html="q.q + ' <strong>,</strong> '"
                  />
                </template>
                <app-collapse-item
                  v-else
                  :title="listItem.name"
                >
                  <b-row class="align-items-stretch">
                    <b-col cols="12">
                      <ul class="list-preview-ans">
                        <li
                          v-for="(value, index2) in listItem.answers"
                          :key="index2"
                          class="item_quiz_create list-preview-ans--item"
                        >
                          <div class="list-preview-ans--heading">
                            <b-badge
                              class="create-question-item "
                              :variant=" value.is_correct ? 'light-success' : 'light-danger'"
                            >
                              {{ answerIndex[index2] }}
                            </b-badge>
                            <div
                              style="width: 76%"
                              class="mb-0 title-ans ml-1"
                              v-html="value.name"
                            />
                          </div>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col
                      v-if="listItem.image"
                      md="6"
                    >
                      <b-img
                        style="margin-left: 0px !important; max-width: 100%"
                        center
                        class="mb-1 mt-1"
                        height="200"
                        :src="listItem.image.path"
                      />

                    </b-col>
                    <b-col
                      v-if="listItem.audio "
                      md="6"
                    >
                      <audio
                        class="mt-2"
                        controls
                      >
                        <source
                          :src="listItem.audio.path"
                          type="audio/ogg"
                        >
                      </audio>
                    </b-col>
                  </b-row>
                </app-collapse-item>
              </app-collapse>
            </div>
          </b-list-group-item>
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="pageLength"
            first-number
            last-number
            class="pagination-danger mt-2"
            align="right"
            @input="onPageChange"
          >
            <template #prev-text>
              <feather-icon
                size="18"
                icon="ChevronLeftIcon"
              />
            </template>
            <template #next-text>
              <feather-icon
                size="18"
                icon="ChevronRightIcon"
              />
            </template>
          </b-pagination>

        </b-list-group>
      </b-col>
    </b-row>
    <b-row
      v-show="choose && listQuestions.length"
      class="mt-2"
    >
      <b-col cols="2">
        <label>Điểm</label>
        <b-form-input
          v-model="point"
          type="number"
          placeholder="Nhập điểm"
        />
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end mt-2">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="danger"
        class="btn-sm"
        @click="listQuestionPart"
      >
        <feather-icon
          size="15"
          icon="PlusCircleIcon"
        />
        Áp dụng
      </b-button>
    </div>
  </b-overlay>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode'
import { VueAutosuggest } from 'vue-autosuggest'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'

import {
  BCardText,
  BAvatar,
  BBadge,
  BButton,
  BCol, BDropdown, BDropdownItem,
  BEmbed,
  BForm, BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput, BFormRadio,
  BFormSelect, BFormTextarea,
  BImg, BInputGroupAppend, BListGroup, BListGroupItem, BModal,
  BOverlay, BPagination,
  BRow, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import config from '@/config'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    VueAutosuggest,
    Prism,
    BCardText,
    BImg,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    BAvatar,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    quillEditor,
    BCol,
    draggable,
    BFormSelect,
    BEmbed,
    BFormFile,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BBadge,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: {
    choose: {
      required: false,
    },
    listQ: {
      required: false,
    },
  },

  data() {
    return {
      cbxAll: false,
      point: 0,
      answerIndex: config.course.answerIndex,
      modalShow: false,
      currentPage: 1,
      pageLength: 30,
      typeQuestion: config.type_question,
      isLoading: false,
      tagOptionByReview: [],
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: 'Tìm kiếm tag',
      },
      total: 0,
      ids: [],
      modalName: '',
      snowOption: {
        theme: 'snow',
        placeholder: this.$t('enter_q'),
      },
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      typeOptions: [
        {
          text: 'Tất cả',
          value: 'all',
        },
        {
          text: 'Trắc nghiệm',
          value: 1,
        },
        {
          text: 'Ghi âm',
          value: 2,
        },
        {
          text: 'Ghép từ',
          value: 3,
        },
        {
          text: 'Ghép cột',
          value: 4,
        },
        {
          text: 'Điền từ',
          value: 5,
        },
      ],
      filter: {
        key: '',
        type: '',
        tag: [],
      },
      filterTag: '',
      sort: {
        by: 'order',
        direction: 'desc',
      },
      tagOptions: [],
      listQuestions: [],
      listQuestionGroups: [],

    }
  },
  watch: {
    'filter.key': {
      handler() {
        this.loadQuestions()
      },
    },
    'filter.tag': {
      handler() {
        this.loadQuestions()
      },
    },
    'filter.type': {
      handler() {
        this.loadQuestions()
      },
    },
  },
  methods: {
    onSelected(option) {
      this.filter.tag = []
      const tag = option.item
      this.filter.tag.push(tag.id)
      this.loadQuestions()
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      this.filterTag = text
      this.loadTags(this)
    },
    loadTags: _.debounce(vm => {
      vm.isLoading = true
      vm.$http.get('/tags', {
        params: {
          key: vm.filterTag,
          type: 0,
          perPage: 100,
        },
      })
        .then(res => {
          vm.tagOptions = res.data.data
          vm.filteredOptions = [{
            data: res.data.data,
          }]
        }).catch()
        .finally(() => {
          vm.isLoading = false
        })
    }, 350),

    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.loadQuestions()
    },
    loadQuestions() {
      if (this.filter.key === '' && this.filter.tag.length === 0 && this.filter.type === '') {
        this.listQuestions = []
      } else {
        this.isLoading = true
        this.$http.get('/questions', {
          params: {
            key: this.filter.key,
            tag: JSON.stringify(this.filter.tag),
            type: this.filter.type,
            listQid: JSON.stringify(this.listQ),
            perPage: this.pageLength,
            page: this.currentPage,
          },
        })
          .then(res => {
            this.listQuestions = res.data.data
            this.total = res.data.total
            this.pageLength = res.data.per_page
          }).catch()
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    listQuestionPart() {
      this.listQuestionGroups.map(v => {
        // eslint-disable-next-line radix,no-param-reassign
        v.point = parseInt(this.point)
        return v
      })
      this.$emit('listQuestionPart', this.listQuestionGroups)
    },
  },

}
</script>
<style lang="scss" scoped>
.question-part{
  max-height: 685px;
  overflow-y: scroll;
}
.detail-question{
  width: 100%;
  .card{
    box-shadow: none;
    background-color: transparent;
  }
}
</style>
