<template>
  <b-overlay
    :show="isLoading"
    :opacity="0.6"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <div class="item_content_row__exercise">
      <b-row style="">
        <b-col
          sm="9"
        >
          <validation-observer ref="updateName">
            <p
              v-if="!isEdit"
              @click="editable"
              v-html="item.name"
            />
            <validation-provider
              v-if="isEdit"
              #default="{ errors }"
              name="Tên nhóm câu hỏi"
              vid="name"
              rules="required"
              mode="passive"
            >
              <quill-editor
                v-model="item.newName"
                required
                :options="snowOption"
                :placeholder="'Nhập câu hỏi'"
                :state="errors.length > 0 ? false:null"
                @blur="handleUpdate(item)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </validation-observer>
        </b-col>
        <b-col sm="3">
          <div class="control_button_row">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon rounded-circle float-right"
              style="margin-left: 5px"
              @click="remove(item.id, index)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon rounded-circle float-right"
              style="margin-left: 5px"
              @click="editName"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <!--          <b-button-->
            <!--                  v-show="frm.type !== 'essay'"-->
            <!--                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
            <!--                  variant="outline-secondary"-->
            <!--                  class="btn-icon rounded-circle float-right xxxx"-->
            <!--                  style="margin-left: 5px"-->
            <!--                  @click="addAnswer"-->
            <!--          >-->
            <!--            <feather-icon icon="PlusIcon" />-->
            <!--          </b-button>-->
          </div>
        </b-col>
      </b-row>
      <validation-observer
        ref="formQuestionRules"
      >
        <div
          v-show="isShow"
          class="box_content_quiz"
        >
          <b-row class="align-items-stretch">
            <b-col cols="9">
              <div class="quiz_content_left">
                <b-row>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="loại câu hỏi"
                      vid="type"
                      rules="required"
                    >
                      <b-form-group>
                        <v-select
                          id="type"
                          v-model="frm.type"
                          class=""
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="label"
                          :options="questionTypeData"
                          :reduce="label => label.value"
                          :state="errors.length > 0 ? false:null"
                          :clearable="false"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="frm.type === 'multiple_choice'"
                    md="6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="loại trắc nghiệm"
                      vid="multiple_choice_type"
                      rules="required"
                    >
                      <b-form-group>
                        <v-select
                          id="multiple_choice_type"
                          v-model="frm.multiple_choice_type"
                          class="style-chooser"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="label"
                          :options="multipleChoiceTypeData"
                          :reduce="label => label.value"
                          :state="errors.length > 0 ? false:null"
                          :clearable="false"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <template
                  v-if="frm.type === 'multiple_choice' || frm.type === 'fill'"
                >
                  <b-list-group class="group_quiz_create">
                    <draggable
                      v-model="frm.answers"
                      tag=""
                      @end="value => onEnd(value)"
                    >
                      <b-list-group-item
                        v-for="(value, index2) in frm.answers"
                        :key="index2"
                        class="item_quiz_create"
                      >
                        <b-row>
                          <b-col cols="1">
                            <b-badge
                              variant="light-danger"
                              class="badge-light-custom"
                            >
                              {{ answerIndex[index2] }}
                            </b-badge>
                          </b-col>
                          <b-col cols="9">
                            <div>
                              <validation-provider
                                #default="{ errors }"
                                name="đáp án"
                                vid="answer_name"
                                rules="required"
                              >
                                <template v-if="frm.type === 'multiple_choice'">
                                  <b-form>
                                    <quill-editor
                                      v-model="value.name"
                                      :options="snowOption"
                                      :placeholder="'Nhập đáp án'"
                                      max-rows="6"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </b-form>
                                </template>

                                <template v-else-if="frm.type ==='fill'">
                                  <b-form-textarea
                                    v-model="value.name"
                                    placeholder="Nhập đáp án"
                                    rows="3"
                                    max-rows="6"
                                    :state="errors.length > 0 ? false:null"
                                  />
                                </template>
                              </validation-provider>
                            </div>
                          </b-col>
                          <b-col
                            cols="1"
                            class="border-checked-none"
                          >
                            <template
                              v-if="frm.multiple_choice_type === 'one_answer'"
                            >
                              <b-input-group-append
                                is-text
                                class="custom_radio_checker"
                              >
                                <b-form-radio
                                  v-model="frm.is_correct"
                                  :value="index2"
                                  :name="`radio-input-${item.id}`"
                                />
                              </b-input-group-append>
                            </template>

                            <template
                              v-else-if="frm.multiple_choice_type === 'many_answers'"
                            >
                              <b-input-group-append is-text>
                                <b-form-checkbox
                                  v-model="value.is_correct"
                                  :name="`checkbox-input-${item.id}`"
                                  :value="true"
                                />
                              </b-input-group-append>
                            </template>
                          </b-col>
                          <b-col cols="1">
                            <feather-icon
                              class="btn_close_item"
                              icon="XIcon"
                              @click="removeAnswer(index2)"
                            />
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </draggable>
                  </b-list-group>
                  <!--                  <b-list-group v-show="showAddAnswer" class="group_quiz_create">-->
                  <!--                    <b-list-group-item class="item_quiz_create" style="background-color: #f5f5f5 !important;">-->
                  <!--                      <b-row class="align-items-center">-->
                  <!--                        <b-col cols="1">-->
                  <!--                          <b-badge variant="light-secondary" class="badge-light-custom">-->
                  <!--                            {{ newAnswerIndex }}-->
                  <!--                          </b-badge>-->
                  <!--                        </b-col>-->
                  <!--                        <b-col cols="11">-->
                  <!--                          <b-input-group>-->
                  <!--                            <b-form-input-->
                  <!--                                    v-model="add_answer"-->
                  <!--                                    placeholder="Thêm đáp án"-->
                  <!--                                    @keydown.enter.native="addAnswer"-->
                  <!--                            />-->
                  <!--                          </b-input-group>-->
                  <!--                        </b-col>-->
                  <!--                      </b-row>-->
                  <!--                    </b-list-group-item>-->
                  <!--                  </b-list-group>-->

                  <b-button
                    v-show="frm.type !== 'essay'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-secondary"
                    class="btn-icon float-left mt-1 button_create_ansewer"
                    style=""
                    @click="addAnswer"
                  >
                    Tạo đáp án mới
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </template>
                <b-row>
                  <b-col cols="12">
                    <template />
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              cols="3"
            >
              <div class="block_upload_group">
                <div>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="ảnh"
                      vid="image"
                    >
                      <b-form-file
                        v-model="frm.image"
                        class="format_input_file"
                        placeholder=""
                        drop-placeholder="Drop file here..."
                        accept="image/*"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <div
                      v-if="frm.image"
                      class="my-1"
                    >
                      File đã chọn: <strong>{{ frm.image.name }}</strong>
                    </div>
                    <b-card-text
                      v-if="imgUrl"
                      class="mt-1"
                      style="position: relative"
                    >
                      <span
                        class="btn_remove_imgUpload"
                        @click="deleteMedia(item.id,1)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 512 512"
                        ><path
                          d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
                        /></svg>
                      </span>
                      <b-img
                        center
                        class="mb-1 mb-sm-0"
                        height="150"
                        :src="imgUrl"
                        alt="Right image"
                      />
                    </b-card-text>
                  </b-form-group>

                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="audio"
                      vid="audio"
                    >
                      <b-form-file
                        v-model="frm.audio"
                        class="format_input_file"
                        placeholder=""
                        drop-placeholder="Drop file here..."
                        accept=".mp3"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <div
                      v-if="frm.audio"
                      class="my-1"
                    >
                      File đã chọn: <strong>{{ frm.audio ? frm.audio.name : '' }}</strong>
                    </div>
                    <!--                    <b-embed-->
                    <!--                      v-if="audioUrl"-->
                    <!--                      type="embed"-->
                    <!--                      :src="audioUrl"-->
                    <!--                    />-->
                    <div
                      v-if="audioUrl"
                      style="position: relative;"
                    >
                      <audio
                        controls="controls"
                        style="width: 352px;margin-top: 30px"
                      >
                        <source
                          :src="audioUrl"
                          type="audio/mp3"
                        >
                      </audio>
                      <span
                        class="btn_remove_imgUpload"
                        @click="deleteMedia(item.id,0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 512 512"
                        ><path
                          d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
                        /></svg>
                      </span>
                    </div>
                  </b-form-group>
                </div>

                <div class="d-flex align-items-sm-center justify-content-flex-end;">
                  <b-form-group style="width: 100%">
                    <validation-provider
                      #default="{ errors }"
                      name="điểm"
                      vid="point"
                      rules="required"
                    >
                      <label
                        for="point"
                        :class="errors.length > 0 ? 'text-danger' : ''"
                      >Điểm <span class="text-danger">(*)</span></label>
                      <b-form-input
                        id="point"
                        v-model="frm.point"
                        type="number"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Điểm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-button
                    class="float-right"
                    variant="danger"
                    :disabled="isLoading"
                    style="margin: 10px 0 0 15px; min-width: 76px;"
                    @click="submit"
                  >
                    Lưu
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </validation-observer>
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BFormTextarea, BFormInput, BOverlay, BButton, BFormGroup, BInputGroup, BFormRadio, BInputGroupAppend, BBadge, BFormCheckbox, BFormFile, BCardText, BImg, BEmbed, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import { required } from '@validations'
import draggable from 'vuedraggable'
import config from '@/config'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    quillEditor,
    vSelect,
    BFormGroup,
    BInputGroup,
    BFormRadio,
    BInputGroupAppend,
    BBadge,
    BFormCheckbox,
    BFormFile,
    BCardText,
    BImg,
    BEmbed,
    draggable,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    test_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      is_correct: null,
      isShow: false,
      isEdit: false,
      showAddAnswer: false,
      add_answer: null,
      snowOption: {
        theme: 'snow',
        placeholder: 'Nhập câu hỏi',
      },
      isLoading: false,
      frm: {
        name: null,
        type: null,
        multiple_choice_type: null,
        answers: [],
      },
      rows: [],
      modalVideoShow: false,
      modalVideoName: null,
      imgUrl: null,
      audioUrl: null,
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      questionTypeData: config.course.questionType,
      multipleChoiceTypeData: config.course.multipleChoiceType,
      answerIndex: config.course.answerIndex,
      required,
    }
  },
  computed: {
    newAnswerIndex() {
      return this.answerIndex[this.frm.answers.length]
    },
  },
  watch: {
    'frm.type': {
      handler(newVal) {
        if (['fill', 'essay'].includes(newVal)) {
          this.frm.multiple_choice_type = null
        } else if (!this.frm.multiple_choice_type) this.frm.multiple_choice_type = 'one_answer'

        if (['fill', 'multiple_choice'].includes(newVal)) {
          this.showAddAnswer = true
        }
      },
    },
    'frm.multiple_choice_type': {
      handler(newVal, oldVal) {
        console.log('aaaa', newVal, oldVal)
        if (this._.isEqual([newVal, oldVal].sort(), ['one_answer', 'many_answers'].sort())) {
          console.log('bbbbbb')
          this.frm.answers = this.frm.answers.map(v => {
            // eslint-disable-next-line no-param-reassign
            v.is_correct = false
            return v
          })
        }
      },
    },
  },
  created() {
    this.frm.name = this.item.name
    this.frm.type = this.item.type
    this.frm.point = this.item.point
    this.frm.multiple_choice_type = this.item.multiple_choice_type
    this.frm.answers = this.item.answers
    if (this.item.image) this.imgUrl = `${this.endPoint}/storage${this.item.image}`
    if (this.item.audio) this.audioUrl = `${this.endPoint}/storage${this.item.audio}`

    if (this.frm.type === 'multiple_choice') {
      if (this.frm.multiple_choice_type === 'one_answer') {
        this.frm.is_correct = this._.findIndex(this.frm.answers, { is_correct: 1 })
      } else if (this.frm.multiple_choice_type === 'many_answers') {
        this.frm.answers = this.frm.answers.map(v => {
          // eslint-disable-next-line no-param-reassign
          v.is_correct = v.is_correct === 1
          return v
        })
      }
    }

    console.log('aaaa', this.item.name, this.frm.answers)
  },
  methods: {
    deleteMedia(id, type) {
      this.$swal({
        title: 'Bạn có chắc xóa không ?',
        text: 'Bạn sẽ không thể lấy lại dữ liệu !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          const formData = new FormData()
          formData.append('id', id)
          formData.append('type', type)

          this.$http({
            method: 'post',
            url: 'questions/deleteMedia',
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.isLoading = false
              this.showToast('success', 'Xóa media thành công')
              if (type) {
                this.imgUrl = null
              } else {
                this.audioUrl = null
              }
            }).catch(e => {
              this.showToast('danger', 'Xóa media thất bại')
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    editable() {
      this.isShow = !this.isShow
      console.log('this.isShow', this.isShow)
    },
    editName() {
      this.isEdit = !this.isEdit
      console.log('this.isEdit', this.isEdit)
    },
    loadQuestions() {
      const params = {}

      params.question_group_id = this.item.id

      this.isLoading = true
      this.$http.get('/questions', {
        params,
      })
        .then(res => {
          this.rows = res.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    submit() {
      localize('vi', vi)

      this.$refs.formQuestionRules.validate().then(success => {
        if (success) {
          const formData = new FormData()

          if (this.frm.name) formData.append('name', this.frm.name)
          // formData.append('question_group_id', this.item.id)
          if (this.frm.image) formData.append('image', this.frm.image)
          if (this.frm.audio) formData.append('audio', this.frm.audio)

          if (this.frm.point) formData.append('point', this.frm.point)
          if (this.frm.type) formData.append('type', this.frm.type)
          formData.append('test_id', this.test_id)
          console.log('multiple_choice_type', this.frm.multiple_choice_type)
          if (this.frm.multiple_choice_type) formData.append('multiple_choice_type', this.frm.multiple_choice_type)

          if (this.frm.type === 'essay') {
            this.frm.answers = []
          }

          if (this.frm.answers.length) {
            if (this.frm.type === 'multiple_choice' && this.frm.multiple_choice_type === 'one_answer') {
              this.frm.answers = this.frm.answers.map((value, index) => {
                // eslint-disable-next-line no-param-reassign
                value.is_correct = index === this.frm.is_correct ? 1 : 0
                return value
              })
            }

            formData.append('answers', JSON.stringify(this.frm.answers))
          }
          formData.append('_method', 'PUT')

          console.log('this.frm', this.frm)
          this.isLoading = true
          this.$http({
            method: 'post',
            url: `/questions/${this.item.id}`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              const { data } = res
              // eslint-disable-next-line radix
              if (data.question.image) this.imgUrl = `${this.endPoint}/storage${data.question.image}`
              if (data.question.audio) this.audioUrl = `${this.endPoint}/storage${data.question.audio}`
              this.$emit('update-total-point', data.totalPoint)
              this.modalShow = false
              this.showToast('success', 'Cập nhật thành công')
              this.isShow = false
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              console.log('error', error)
              let errorMsg = {}
              if (error.response.status === 422) {
                errorMsg = error.response.data
              }

              this.$refs.formQuestionRules.setErrors(errorMsg)
              let textToast = 'Cập nhật thất bại'
              if (errorMsg.answers && errorMsg.answers[0]) textToast += ` (${errorMsg.answers[0]})`
              this.showToast('danger', textToast)
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    removeAnswer(index) {
      if (this.frm.type === 'multiple_choice' && this.frm.multiple_choice_type === 'one_answer') {
        if (index < this.frm.is_correct) {
          this.frm.is_correct -= 1
        } else if (index === this.frm.is_correct) {
          this.frm.is_correct = null
        }
      }

      this.frm.answers.splice(index, 1)
    },
    addAnswer() {
      if (this.frm.answers.length < 9) {
        this.frm.answers.push({
          name: this.add_answer,
          is_correct: false,
        })

        this.add_answer = null
      }
    },
    onEnd(value) {
      console.log('aaaaa', value.oldIndex, value.newIndex)
      if (this.frm.is_correct === value.oldIndex) {
        this.frm.is_correct = value.newIndex
      } else if (this.frm.is_correct >= Math.min(value.oldIndex, value.newIndex) && this.frm.is_correct <= Math.max(value.oldIndex, value.newIndex)) {
        if (value.newIndex < value.oldIndex) {
          this.frm.is_correct += 1
        } else {
          this.frm.is_correct -= 1
        }
      }
    },
    changeOrder(oldOrder, newOrder) {
      console.log({ oldOrder, newOrder })
      this.isLoading = true
      this.$http.put('/videos', { oldOrder, newOrder })
      // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.showToast('success', 'Cập nhật thứ tự thành công')
          // eslint-disable-next-line no-unused-vars
        }).catch(e => {
          this.showToast('danger', 'Cập nhật thứ tự thất bại')
        }).finally(() => {
          this.isLoading = false
          this.loadItems()
        })
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Thông báo',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    remove(id, index) {
      this.$swal({
        title: 'Bạn có chắc không',
        text: 'Bạn sẽ không thể lấy lại dữ liệu đã xóa',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Chắc chắn !',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          this.$http.delete(`/questions/${id}/${this.test_id}`)
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.showToast('success', 'Xoá câu hỏi thành công')
              this.$emit('deleteQuestion', index)

              // emit total Point
              this.$emit('update-total-point', res.data.totalPoint)
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              this.showToast('danger', 'Xoá câu hỏi thất bại')
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    toggleEdit() {
      console.log('aaaaa', 'aaaaaa')
      this.item.edit = !this.item.edit
      this.item.newName = this.item.name
    },
    handleUpdate(item) {
      localize('vi', {
        code: 'vi',
        messages: {
          required: 'Vui lòng nhập {_field_}',
        },
      })

      if (item.newName === item.name) return

      this.$refs.updateName.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.$http({
            method: 'put',
            url: `/questions/update-name/${item.id}`,
            data: {
              name: item.newName,
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.showToast('success', 'Cập nhật thành công')
              // eslint-disable-next-line no-param-reassign
              item.name = item.newName
              this.isEdit = false
            })
          // eslint-disable-next-line no-unused-vars
            .catch(e => {
              this.showToast('danger', 'Cập nhật thất bại')
              // eslint-disable-next-line no-param-reassign
              item.newName = item.name
            }).finally(() => {
              this.isLoading = false
              // eslint-disable-next-line no-param-reassign
              item.edit = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.btn_remove_imgUpload{
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 1;
}

.btn_remove_imgUpload svg{
  width: 21px;
  color: #cfcfcf;
}
.btn_remove_imgUpload svg:hover{
  color: #000;
}

.UploadImgWithItem{
  width: 120px;
}
.box_content_quiz .UploadImgWithItem .input_file.updload_file_img label.custom-file-label:after {
  content: "Tải ảnh" !important;
  background: #f1f1f1;
  border-radius: 0 !important;
  height: 100%;
  color: #878787;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.box_content_quiz .UploadImgWithItem .input_file label.custom-file-label{
  height: 100% !important;
  border-radius: 0;
  border: 1px solid #d8d6de;
}
.UploadImgWithItem .custom-file{
  height: 100%;
}

.border-checked-none .input-group-text{
  border: none;
  background-color: transparent;
}
</style>
