<template>
  <b-overlay
    :show="isLoading"
    :opacity="0.6"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <div
      class="item_content_row"
      @mouseleave="pushSkill"
    >
      <div class="item_content_row__item">
        <div
          class="heading_name item_content_row__heading"
          @click="showPart"
        >
          {{ item.name }}
        </div>
        <div class="item_content_row__control">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            style="margin-left: 8px"
            class="btn-icon rounded-circle float-right"
            @click="deleteSkill(index)"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon rounded-circle float-right"
            @click="addPart"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>

          <b-button
            class="button_open_exercise"
            variant="outline-secondary"
            @click="showPart"
          >
            <feather-icon :icon="isShow ? 'ChevronDownIcon' : 'ChevronRightIcon'" />
          </b-button>
        </div>
      </div>
    </div>
    <div v-show="isShow">
      <template v-if="skill.parts.length">
        <b-list-group class="list-group-child-exercise">
          <draggable
            v-model="skill.parts"
            tag="tbody"
            class="list-group list-group-flush cursor-move"
            @end="pushSkill"
          >
            <b-list-group-item
              v-for="(part, index) in skill.parts"
              :key="index"
              class="align-items-center"
              variant=""
            >
              <part
                :item="part"
                :index="index"
                :list-q="listQ"
                :tag-options="tagOptions"
                :tag-option-by-review="tagOptionByReview"
                :type="type"
                @deletePart="deletePart"
                @editPart="editPart"
                @enable="enable"
              />
            </b-list-group-item>
          </draggable>
        </b-list-group>
      </template>
      <template v-else>
        <b-badge
          href="#"
          class="d-block"
          pill
          variant="danger"
        >
          Chưa có dữ liệu+
        </b-badge>
      </template>
    </div>
  </b-overlay>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import draggable from 'vuedraggable'

import {
  BRow,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
  BOverlay,
  BListGroup,
  BListGroupItem,
  BFormFile,
  BFormTextarea,
  BBadge,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import part from './Part'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    part,
    BFormCheckbox,
    BOverlay,
    BListGroup,
    BListGroupItem,
    ValidationProvider,
    ValidationObserver,
    draggable,
    BFormFile,
    BFormTextarea,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    tagOptions: {
      type: Array,
      required: true,
    },
    tagOptionByReview: {
      type: Array,
      required: true,
    },
    listQ: {
      required: false,
    },
    index: {
      type: Number,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isShow: true,
      showQ: false,
      partItem: [],
      skill: {
        index: this.index,
        parts: [],
      },
    }
  },
  created() {
    if (this.item.parts.length) {
      this.skill.parts = this.item.parts
    }
  },
  methods: {
    deleteSkill(index) {
      this.$swal({
        title: 'Bạn có chắc chắn xóa không ?',
        text: 'Bạn sẽ không thể lấy lại dữ liệu đã xóa',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK !',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$emit('deleteSkill', index)
        }
      })
    },
    addPart() {
      const index = this.skill.parts.length + 1
      this.skill.parts.push({
        name: `Part ${index}`,
        image: null,
        audio: null,
        url_fix_video: null,
        questions: [],
      })
    },
    showPart() {
      this.isShow = !this.isShow
    },
    deletePart(index) {
      this.skill.parts.splice(index, 1)
    },
    pushSkill() {
      this.$emit('pushSkill', this.skill)
    },
    editPart(item) {
      const ix = item.index
      this.skill.parts[ix].questions = item.questions
      this.skill.parts[ix].audio = item.audio
      this.skill.parts[ix].image = item.image
      this.skill.parts[ix].url_fix_video = item.url_fix_video
      this.skill.parts[ix].name = item.name
      this.skill.parts[ix].document_type = item.document_type
      this.skill.parts[ix].document_id = item.document_id
      this.skill.parts[ix].document_link = item.document_link
      this.skill.parts[ix].audio_type = item.audio_type
      this.skill.parts[ix].audio_link = item.audio_link
    },
    enable(enable) {
      this.$emit('enable', enable)
    },

  },
}
</script>
