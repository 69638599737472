<template>
  <b-tabs
    v-model="tabIndex"
    class="tabs_custom"
  >
    <b-tab
      :title="$t('general_info')"
    >
      <validation-observer ref="simpleRules">
        <b-form class="form_custom">
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('name_q')"
                      vid="name"
                      rules="required"
                    >
                      <label
                        for="time"
                        :class="errors.length > 0 ? 'text-danger' : ''"
                      >{{ $t('name_q') }} <span class="text-danger">(*)</span></label>
                      <b-form-input
                        v-model="frm.name"
                        type="text"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('time')"
                      rules="required"
                    >
                      <label
                        for="title"
                        :class="errors.length > 0 ? 'text-danger' : ''"
                      >{{ $t('time') }} <span class="text-danger">(*)</span></label>
                      <b-form-input
                        id="title"
                        v-model="frm.time"
                        type="number"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Nhập số phút"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('level')"
                    vid="level"
                    rules="required"
                  >
                    <b-form-group>
                      <label
                        for="level"
                        :class="errors.length > 0 ? 'text-danger': null"
                      >{{ $t('level') }} <span class="text-danger">(*)</span></label>
                      <v-select
                        id="level"
                        v-model="frm.level"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="levelData"
                        :reduce="label => label.id"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('type')"
                    vid="type"
                    rules="required"
                  >
                    <b-form-group>
                      <label
                        for="type"
                        :class="errors.length > 0 ? 'text-danger': null"
                      >{{ $t('type') }} <span class="text-danger">(*)</span></label>
                      <v-select
                        id="type"
                        v-model="frm.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="label"
                        :options="typeData"
                        :reduce="label => label.value"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <validation-provider
                    v-if="frm.type === 1"
                    #default="{ errors }"
                    :name="$t('pass_lesson')"
                    vid="type"
                    rules="required|min_value:0"
                  >
                    <b-form-group>
                      <label
                        for="title"
                        :class="errors.length > 0 ? 'text-danger' : ''"
                      >{{ $t('pass_lesson') }} <span class="text-danger">(*)</span></label>
                      <b-form-input
                        id="pass_lesson"
                        v-model="frm.pass_lesson"
                        type="number"
                        placeholder="Nhập số điểm qua bài"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <label for="status">{{ $t('status') }}</label>
                    <b-form-checkbox
                      id="status"
                      v-model="frm.status"
                      class="custom-control-danger"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-button
                variant="danger"
                type="submit"
                class="btn btn-sm float-right"
                :disabled="tabIndex !== 0"
                @click.prevent="validationForm"
              >
                <feather-icon
                  size="15"
                  icon="SaveIcon"
                />
                {{ $t("save_info") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-tab>
    <b-tab
      :title="$t('content_exam')"
      :disabled="!frm.id"
    >
      <exercise-edit
        v-if="frm.type === 1"
        :rows="questionGroups"
        :exercise-id="parseInt(frm.id)"
        :total-point="totalPoint"
        :pass-score="parseInt(frm.pass_lesson)"
        :list-q="listQ"
        @update-total-point="updateTotalPoint"
      />
      <template v-else>
        <b-row>
          <b-col md="12">
            <vue-autosuggest
              :suggestions="filteredOptions"
              :limit="10"
              :input-props="inputProps"
              @selected="onSelected"
              @input="onInputChange"
            >
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
              </template>
            </vue-autosuggest>
          </b-col>
        </b-row>
        <b-skeleton-wrapper
          v-if="isLoading"
          :loading="true"
        >
          <template #loading>
            <b-card>
              <b-skeleton width="85%" />
              <b-skeleton width="55%" />
              <b-skeleton width="70%" />
            </b-card>
          </template>
        </b-skeleton-wrapper>
        <template v-else>
          <b-row class="mt-2">
            <b-col md="12">
              <b-list-group
                flush
                class="list_group_custom list_group_custom-exercise"
              >
                <draggable
                  v-model="skillData"
                  tag=""
                  class="list-group list-group-flush cursor-move"
                >
                  <b-list-group-item
                    v-for="(item, index) in skillData"
                    :id="item.id"
                    :key="index"
                    class="align-items-center"
                  >
                    <skill
                      :index="index"
                      :item="item"
                      :list-q="listQ"
                      :total-point="totalPoint"
                      :type="frm.type"
                      :tag-options="tagOptions"
                      :tag-option-by-review="tagOptionByReview"
                      @deleteSkill="deleteSkill"
                      @pushSkill="pushSkill"
                    />
                  </b-list-group-item>
                </draggable>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="12"
              class="mt-2"
            >
              <b-button
                variant="danger"
                type="submit"
                class="btn btn-sm float-right"
                @click.prevent="compilation"
              >
                <feather-icon
                  size="15"
                  icon="SaveIcon"
                />
                Lưu thông tin bài thi
              </b-button>
            </b-col>
          </b-row>
        </template>
      </template>

    </b-tab>
  </b-tabs>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
  BOverlay,
  BTab,
  BTabs,
  BListGroup,
  BBadge,
  BListGroupItem, VBModal, VBTooltip,
  BSkeletonWrapper, BSkeleton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required, url, min_value } from '@validations'
import draggable from 'vuedraggable'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// eslint-disable-next-line import/no-unresolved
import { VueAutosuggest } from 'vue-autosuggest'
import Ripple from 'vue-ripple-directive'
import ExerciseEdit from '@/views/capacity/ExerciseEdit'
import config from '@/config'
import skill from '../Skill.vue'

export default {
  components: {
    BRow,
    BSkeletonWrapper,
    BSkeleton,
    BCol,
    ExerciseEdit,
    BBadge,
    draggable,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BFormInput,
    skill,
    ValidationProvider,
    BButton,
    ValidationObserver,
    BCardCode,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BForm,
    BFormCheckbox,
    BOverlay,
    BTab,
    BTabs,
    // ExerciseEdit,
    vSelect,
    VueAutosuggest,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: this.$t('choose_skill'),
      },
      totalPoint: 0,
      tagOptions: [],
      tagOptionByReview: [],
      listQ: [],
      isLoading: false,
      disabled: true,
      tabIndex: 0,
      frm: {
        id: this.$route.params.id,
        name: null,
        status: true,
        time: null,
        level: null,
        type: null,
        pass_lesson: null,
      },
      // eslint-disable-next-line global-require
      imgUrl: null,
      Skills: [],
      options: [
        { text: 'Hoạt động', value: 1, disabled: false },
        { text: 'Không hoạt động', value: 0, disabled: false },
      ],
      required,
      url,
      min_value,
      lecturersData: [],
      levelData: [],
      typeData: config.type_exercise,
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      filterSkill: {
        key: '',
      },
      skillData: [],
      filteredOptions: [],
      questionGroups: [],
      selected: null,
    }
  },
  watch: {
    tabIndex: {
      handler(value) {
        if (value === 0) {
          if (this.frm.id) this.loadItem()
        }
        if (value === 1) {
          if (this.frm.id) {
            if (this.frm.type === 1) {
              this.loadQuestionGroup()
            } else {
              this.loadCompilation()
              this.loadTags()
              this.loadTagReviews()
            }
          }
        }
      },
    },
  },
  created() {
    if (this.frm.id) this.loadItem()
    this.loadLevels()
  },
  methods: {
    // eslint-disable-next-line no-shadow
    pushSkill(skill) {
      this.skillData[skill.index].parts = skill.parts
    },
    loadLevels() {
      this.isLoading = true
      this.$http.get('levels')
        .then(res => {
          this.levelData = res.data.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    loadTags() {
      this.isLoading = true
      this.$http.get('/tags', {
        params: {
          type: 0,
          perPage: 100,
        },
      })
        .then(res => {
          const tagData = res.data.data
          tagData.map(v => {
            if (v.type === 0) {
              this.tagOptions.push(v)
            }
          })
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    loadTagReviews() {
      this.isLoading = true
      this.$http.get('/tags', {
        params: {
          type: 1,
          perPage: 100,
        },
      })
        .then(res => {
          this.tagOptionByReview = res.data.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    onSelected(option) {
      const skill = option.item
      this.skillData.push({
        id: skill.id,
        name: skill.name,
        parts: [],
      })
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      this.filterSkill.key = text
      this.loadSkills()
    },
    updateTotalPoint(point) {
      if (point) {
        this.loadQuestionGroup()
      }
    },
    loadQuestionGroup() {
      this.isLoading = true
      this.$http.get('/question-groups', {
        params: {
          exercise_id: this.frm.id,
        },
      })
        .then(res => {
          const qgr = res.data.question_group.data
          this.totalPoint = parseInt(res.data.totalPoint)
          this.listQ = res.data.listQ
          this.questionGroups = qgr.map(v => {
            // eslint-disable-next-line no-param-reassign
            v.edit = false
            // eslint-disable-next-line no-param-reassign
            v.newName = v.name
            // eslint-disable-next-line no-param-reassign
            v.toggle = false
            return v
          })
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },

    loadItem() {
      this.isLoading = true
      this.$http.get(`/lessons/${this.frm.id}`)
        .then(res => {
          const { data } = res
          this.frm.name = data.name
          this.frm.status = data.status ? 'true' : 'false'
          this.frm.time = data.time
          this.frm.level = data.level_id
          this.frm.type = data.type_exercise
          this.frm.pass_lesson = data.pass_lesson
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    loadSkills() {
      this.isLoading = true
      this.$http.get('/skills', {
        params: {
          key: this.filterSkill.key,
          type: this.frm.type,
        },
      })
        .then(res => {
          this.filteredOptions = [{
            data: res.data.data,
          }]
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    deleteSkill(index) {
      this.skillData.splice(index, 1)
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()
          formData.append('name', this.frm.name)
          if (this.frm.time) formData.append('time', this.frm.time)
          formData.append('level_id', this.frm.level)
          formData.append('type_exercise', this.frm.type)
          formData.append('type', 1)
          if (this.frm.pass_lesson) {
            formData.append('pass_lesson', this.frm.pass_lesson)
          }
          formData.append('status', this.frm.status ? 1 : 0)
          if (this.frm.id) formData.append('_method', 'PUT')
          const url = this.frm.id ? `/lessons/${this.frm.id}` : '/lessons'
          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast('Cập nhật thành công', {
                title: 'Thông báo',
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              if (!this.frm.id) {
                this.frm.id = res.data.id
                this.disabled = false
              }
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              let errorMsg = {}
              if (error.response.status === 422) {
                errorMsg = error.response.data
              } else {
                errorMsg.email = [error.response.data.error]
              }

              this.$refs.simpleRules.setErrors(errorMsg)
              this.$bvToast.toast('Cập nhật thất bại', {
                title: 'Thông báo',
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },

    compilation() {
      if (!this.skillData.length) {
        this.$bvToast.toast(this.$t('message.compilation'), {
          title: this.$t('message.notify'),
          icon: 'BellIcon',
          variant: 'danger',
          solid: false,
        })
      } else {
        const skills = []
        let orderSkill = 0
        this.skillData.map((skill, index) => {
          // eslint-disable-next-line no-const-assign
          orderSkill += 1
          skills.push({
            id: skill.id,
            order: orderSkill,
            parts: [],
          })
          if (skill.parts.length) {
            const { parts } = skill
            let orderPart = 0
            parts.map(part => {
              orderPart += 1
              // handle question
              const qId = []
              if (part.questions) {
                let orderQ = 0
                if (typeof part.questions === 'object') {
                  part.questions = Object.values(part.questions)
                }
                part.questions.map(q => {
                  orderQ += 1
                  const childId = []
                  if (!(q.questions === undefined)) {
                    let orderQChild = 0
                    q.questions.map(child => {
                      orderQChild += 1
                      childId.push({
                        id: child.id,
                        order: orderQChild,
                        // eslint-disable-next-line radix
                        point: child.point ? parseInt(child.point) : null,
                      })
                    })
                  }
                  qId.push({
                    id: q.id,
                    order: orderQ,
                    // eslint-disable-next-line radix
                    child: q.questions ? childId : null,
                  })
                })
              }

              skills[index].parts.push({
                name: part.name,
                order: orderPart,
                url_fix_video: part.url_fix_video,
                image: part.image ? part.image.id : null,
                audio: part.audio ? part.audio.id : null,
                questions: qId,
                document_type: part.document_type,
                document_id: part.document_id,
                document_link: part.document_link,
                audio_type: part.audio_type,
                audio_link: part.audio_link,
              })

              return part
            })
          }
          return skill
        })

        const formData = new FormData()
        formData.append('exercise_id', this.frm.id)
        formData.append('compilation', JSON.stringify(skills))
        // eslint-disable-next-line no-shadow
        const url = '/compilation'

        this.$http({
          method: 'post',
          url,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(res => {
            this.skillData = []
            this.loadCompilation()
            this.$bvToast.toast(this.$t('compilation_success'), {
              title: this.$t('message.notify'),
              icon: 'BellIcon',
              variant: 'success',
              solid: false,
            })
          })
          .catch(error => {
            this.$bvToast.toast(this.$t('upload_err'), {
              title: this.$t('message.notify'),
              icon: 'BellIcon',
              variant: 'danger',
              solid: false,
            })
          }).finally(() => {
            this.isLoading = false
          })
      }
    },
    loadCompilation() {
      this.isLoading = true
      this.$http.get('/compilation', {
        params: {
          exercise_id: this.frm.id,
        },
      })
        .then(res => {
          this.skillData = res.data.skill
          this.listQ = res.data.listQ
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

table.permission_list th, table.permission_list td {
  text-align: center;
}

table.permission_list tr:nth-child(odd) {
  background-color: #f8f8f8
}
</style>
