<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
  >
    <div
      class="item_content_row"
      style="font-weight: unset;"
    >
      <div class="item_content_row__item lever-chill">
        <div
          class="heading_name item_content_row__heading"
          @click="showQ = !showQ"
          v-html="item.name"
        />
        <div class="item_content_row__control">
          <div class="setting-part">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              style="margin-left: 8px"
              class="btn-icon rounded-circle float-right"
              @click="deletePart(index)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon rounded-circle float-right"
              style="margin-left: 5px"
              @click="handleEdit(item)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              size="very small"
              dropleft
              class="rounded-circle float-right button-add float-right"
            >
              <template #button-content>
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item @click="modalQuestionType = true">
                <feather-icon
                  icon="PlusIcon"
                />
                Thêm câu hỏi
              </b-dropdown-item>
              <b-dropdown-item @click="addQuestion">
                <feather-icon
                  icon="DatabaseIcon"
                />
                Thư viện câu hỏi
              </b-dropdown-item>
              <b-dropdown-item @click="showModalQuestionGroup">
                <feather-icon
                  icon="GridIcon"
                />
                {{ $t('add_q_g') }}
              </b-dropdown-item>
              <b-dropdown-item @click="loadChapterByPart">
                <feather-icon
                  icon="FlagIcon"
                />
                Chapter
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <b-button
            class="button_open_exercise"
            variant="outline-secondary"
            @click="showQ = !showQ"
          >
            <feather-icon :icon="showQ ? 'ChevronDownIcon' : 'ChevronRightIcon'" />
          </b-button>
        </div>
      </div>
    </div>
    <div v-show="showQ">
      <template v-if="frmPart.questions">
        <b-list-group class="list-group-child-exercise">
          <draggable
            v-model="frmPart.questions"
            class="list-group list-group-flush cursor-move"
            @end="onEnd"
          >
            <b-list-group-item
              v-for="(question, index) in frmPart.questions"
              :key="index"
              class="align-items-center item-container"
              variant=""
            >
              <b-row>
                <b-col cols="9">
                  <div
                    class="demo-inline-spacing ml-3"
                    style="flex-wrap: nowrap;"
                  >
                    <b-badge
                      class="number_item"
                      variant="warning"
                    >
                      {{ index + 1 }}
                    </b-badge>
                    <span
                      v-if="question.questions === undefined"
                      style="padding: 9px 0px;"
                      @click="handleEditQuestion(question, index)"
                      v-html="question.name"
                    />
                    <span
                      v-else
                      style="padding: 9px 0px;"
                      @click="showQuestionGroup()"
                      v-html="question.name"
                    />

                  </div>
                </b-col>
                <b-col sm="3">
                  <div class="setting_exam">
                    <span
                      class=" float-right"
                      variant="outline-secondary"
                      style="
                      border: none !important;
                      margin-left: 8px;
                      width: 30px;
                      height: 30px;
                      text-align: center;
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
"
                      @click="showQuestionGroup"
                    >
                      <feather-icon :icon="showQgr ? 'ChevronDownIcon' : 'ChevronRightIcon'" />
                    </span>
                    <b-button
                      v-if="!(question.questions === undefined)"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-secondary"
                      class="btn-icon rounded-circle float-right"
                      style="margin-left: 5px"
                      @click="handleEditQuestionGroup(question)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-secondary"
                      style="margin-left: 8px"
                      class="btn-icon rounded-circle float-right"
                      @click="deleteQuestion(index)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                    <b-button
                      v-if="typeof(question.questions) === 'undefined'"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-secondary"
                      class="btn-icon rounded-circle float-right"
                      style="margin-left: 5px"
                      @click="handleEditQuestion(question, index)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-list-group
                v-show="showQgr"
                class="list-group-child-exercise mt-1"
              >
                <draggable
                  v-model="question.questions"
                  class="list-group list-group-flush cursor-move"
                >
                  <b-list-group-item
                    v-for="(child, index1) in question.questions"
                    :key="index1"
                    class="align-items-center"
                    variant=""
                  >
                    <b-row>
                      <b-col cols="11">
                        <div
                          class="demo-inline-spacing list-children-3"
                          style="flex-wrap: nowrap;"
                        >
                          <b-badge variant="warning">
                            {{ index + 1 }}.{{ index1 + 1 }}
                          </b-badge>
                          <span
                            style="padding: 15px 0px;"
                            @click="handleEditQuestion(child, index,index1)"
                            v-html="child.name"
                          />
                        </div>
                      </b-col>
                      <b-col cols="1">
                        <div class="setting_exam">
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-secondary"
                            class="btn-icon rounded-circle float-right"
                            style="margin-left: 5px"
                            @click="handleEditQuestion(child, index,index1)"
                          >
                            <feather-icon icon="Edit2Icon" />
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </draggable>
              </b-list-group>
            </b-list-group-item>
          </draggable>
        </b-list-group>
      </template>
      <template v-else>
        <b-badge
          href="#"
          class="d-block"
          pill
          variant="danger"
        >
          Chưa có câu hỏi
        </b-badge>
      </template>
    </div>
    </div>

    <b-modal
      id="modal-edit"
      v-model="modalShow"
      cancel-variant="outline-secondary"
      :ok-title="$t('save_info')"
      cancel-title="Cancel"
      centered
      :title="$t('edit_part')"
      size="lg"
      :no-close-on-esc="false"
      :no-close-on-backdrop="false"
      @ok.prevent="editPart"
    >
      <validation-observer ref="editPart">
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('name_q')"
              rules="required"
            >
              <label :class="errors.length > 0 ? 'text-danger': null">
                {{ $t('name_q') }} <span class="text-danger">(*)</span></label>
              <b-form-textarea
                v-model="frmPart.name"
                rows="4"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('url_fix_video')"
              rules="url"
            >
              <label
                :class="errors.length > 0 ? 'text-danger' : ''"
              >{{ $t('url_fix_video') }}</label>
              <b-form-input
                id="url_fix_video"
                v-model="frmPart.url_fix_video"
                type="text"
                :state="errors.length > 0 ? false:null"
                :placeholder="$t('url_fix_video')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row
          v-if="frmPart.url_fix_video"
          class="mt-2"
        >
          <b-col md="4">
            <label>Tài liệu chữa đề</label>
            <b-form-group class=" custom_select_group">
              <v-select
                v-model="frmPart.document_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :options="option"
                :reduce="label => label.value"
                placeholder="Chọn thể loại"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="7"
            class="d-flex align-items-center"
          >
            <b-form-group
              class="form_custom mt-2"
              style="width: 100%"
            >
              <validation-provider
                v-if="frmPart.document_type === 'link'"
                #default="{ errors }"
                name="document_link"
                vid="document_link"
                rules="required|url: {require_protocol: true }"
              >
                <b-form-input
                  id="document_link"
                  v-model="frmPart.document_link"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Nhập đường dẫn tài liệu"
                />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>

              <template v-if="frmPart.document_type === 'upload'">
                <b-card-text
                  v-if="frmPart.document_file"
                  class="text-center"
                  style="color: #FF7F00;overflow-wrap: break-word;"
                >
                  {{ frmPart.document_file.name }}
                </b-card-text>
                <upload-media
                  v-else
                  class="library-document"
                  :choose="2"
                  @uploadMedia="uploadMedia"
                />
              </template>

            </b-form-group>
          </b-col>
        </b-row>
        <upload-media
          class="btn_upload_custom"
          :choose="4"
          :image="frmPart.image"
          :audio="frmPart.audio"
          :audio_type="frmPart.audio_type"
          @uploadMedia="uploadMedia"
        />
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-edit"
      v-model="modalShowQuestion"
      class="list-question-part"
      ok-variant="danger"
      title="Thư viện câu hỏi"
      hide-footer
      centered
      size="xl"
      @hide="pushPart"
    >
      <question-library
        :list-q="listQ"
        @listQuestionPart="listQuestionPart"
      />

    </b-modal>
    <b-modal
      id="modal-edit"
      v-model="modalShowQuestionGroup"
      ok-variant="danger"
      hide-footer
      centered
      size="xl"
      :title="titleQgr"
      @hide="pushPart"
    >
      <question-group
        :qgr="dataQuestionGroup"
        :qgr-id="qgrId"
        :list-q="listQ"
        @questionGroup="questionGroup"
      />
    </b-modal>
    <b-modal
      id="modal-edit"
      v-model="modalShowAddQuestion"
      :ok-title="$t('save_info')"
      ok-variant="danger"
      :cancel-title="$t('cancel')"
      centered
      size="lg"
      hide-footer
      title="Chỉnh sửa câu hỏi"
    >
      <multipeChoice
        :question="propQuestion"
        @question="pushQuestion"
      />
    </b-modal>
    <b-modal
      id="modal-edit"
      v-model="modalQuestionType"
      ok-variant="danger"
      hide-footer
      centered
      size="md"
      title="Chọn loại câu hỏi"
    >
      <b-row>
        <b-col
          v-for="(q) in typeQuestion"
          md="4"
          style="margin-bottom: 20px;"
        >
          <div class="demo-inline-spacing">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              style="border: 1px dashed #d7d7d7 !important;"
              target="_blank"
              :to="{ name: q.to}"
            >
              <feather-icon
                v-if="q.icon"
                :icon="q.icon"
                class="mr-50"
              />
              {{ q.text }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="modal-edit"
      v-model="showChapter"
      ok-variant="danger"
      hide-footer
      centered
      size="lg"
      title="Chapter video chữa đề"
      @ok.prevent=""
    >
      <template v-if="item.url_fix_video">
        <validation-observer ref="addChapter">
          <b-row>
            <b-col md="5">
              <validation-provider
                #default="{ errors }"
                name="Tên chapter"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    id="name"
                    v-model="frmChapter.name"
                    placeholder="Nhập tên chapter"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-col>
            <b-col md="5">
              <validation-provider
                #default="{ errors }"
                name="Thời gian"
                rules="required"
              >
                <b-form-group>
                  <cleave
                    id="time"
                    v-model="frmChapter.time"
                    class="form-control"
                    :raw="false"
                    :options="timeOption"
                    placeholder="hh:mm:ss"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="2">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="danger"
                class="btn-sm"
                @click="submitHandleChapter"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="cursor-pointer"
                />
                {{ this.editChapter ? 'Cập nhật' : 'Lưu' }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        <hr>
        <table
          class="table b-table"
        >
          <thead>
            <tr>
              <th
                scope="col"
                width="10"
              >
                STT
              </th>
              <th
                scope="col"
                width="300"
              >
                {{ $t("name_q") }}
              </th>
              <th
                scope="col"
                width="100"
              >
                Thời gian
              </th>
              <th width="100">
                {{ $t("opiton") }}
              </th>
            </tr>
          </thead>
          <template v-if="chapterData.length">
            <template v-if="isLoading" />
            <tr
              v-for="(item, index) in chapterData"
              :id="item.id"
              :key="item.id"
            >
              <td>
                {{ item.order }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.time }}
              </td>
              <td>
                <b-dropdown
                  id="dropdown-1"
                  class="custom-position-dropdown"
                  variant="flat-sencondary"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreHorizontalIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>
                  <div
                    class="dropdown-item"
                    @click="editChapterAction(item)"
                  >
                    {{ $t('edit') }}
                  </div>
                  <div
                    class="dropdown-item"
                    @click="deleteChapter(item.id)"
                  >{{ $t('Delete') }}</div>
                </b-dropdown>
              </td>
            </tr>
          </template>
          <tr
            v-else
          >
            <td colspan="8">
              <p class="text-center">
                {{ $t('no_data') }}
              </p>
            </td>
          </tr>
        </table>
      </template>
      <p
        v-else
        style="text-align: center"
      >
        Vui lòng nhập đường dẫn video chữa đề
      </p>
    </b-modal>
  </b-overlay>
</template>
<script>
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import draggable from 'vuedraggable'
import questionGroup from '@/views/capacity/QuestionGroup'
import uploadMedia from '@/views/library/UploadMedia'
import multipeChoice from '@/views/quiz/multipeChoice'

import {
  BTable,
  BCardText,
  BRow,
  BCard,
  VBTooltip,
  BTab,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
  BOverlay,
  BListGroup,
  BListGroupItem,
  BFormFile,
  BPagination,
  BTabs,
  BFormTextarea,
  BEmbed,
  BImg,
  BBadge, VBModal,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BNavItemDropdown,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required, image, url, email, size, min_value,
} from '@validations'
import config from '@/config'
import vSelect from 'vue-select'
import SweetAlertTypes from '@/views/extensions/sweet-alert/SweetAlertTypes'
import Cleave from 'vue-cleave-component'
import questionLibrary from './QuestionLibrary.vue'
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    multipeChoice,
    BTable,
    BNavItemDropdown,
    SweetAlertTypes,
    uploadMedia,
    Cleave,
    questionGroup,
    BDropdown,
    BDropdownItem,
    BCardText,
    quillEditor,
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCard,
    BCol,
    BBadge,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BForm,
    BFormCheckbox,
    BOverlay,
    BListGroup,
    BListGroupItem,
    questionLibrary,
    ValidationProvider,
    ValidationObserver,
    draggable,
    VBModal,
    BFormFile,
    BTab,
    BTabs,
    BFormTextarea,
    BPagination,
    BEmbed,
    BImg,
    BFormRadio,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    tagOptions: {
      required: true,
    },
    tagOptionByReview: {
      type: Array,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    listQ: {
      required: false,
    },
  },
  data() {
    return {
      dataQuestionGroup: {
        name: '',
        audio: null,
        images: [],
        questions: [],
      },
      typeQuestion: config.type_question,
      chapterData: [],
      modalQuestionType: false,
      qgrId: null,
      modalShowAddQuestion: false,
      indexEditQuestionChild: false,
      currentPageFile: 1,
      totalFile: 0,
      showChapter: false,
      titleQgr: this.$t('add_q_g'),
      dataFiles: [],
      modalShowQuestionGroup: false,
      filterFile: {
        key: '',
        type: 'all',
      },
      editorConfig: {
        toolbar: [{
          name: 'document',
          items: ['Source', '-', 'Undo', 'Redo'],
        },
        {
          name: 'basicstyles',
          items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat'],
        },
        {
          name: 'links',
          items: ['Link', 'Unlink', 'Anchor'],
        },
        {
          name: 'tools',
          items: ['Maximize', 'ShowBlocks', 'About'],
        },
        ],
        height: 80,
      },
      snowOption: {
        theme: 'snow',
        placeholder: this.$t('enter_q'),
        // modules: {
        //   toolbar: [
        //     [{ size: [] }],
        //     [{ header: [1, 2, 3, 4, 5, 6, false] }],
        //     ['bold', 'italic', 'underline', 'strike'],
        //   ],
        // },
      },
      answerIndex: config.course.answerIndex,
      showQgr: false,
      modalShowFile: false,
      modalShowQuestion: false,
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      titleFile: '',
      tab: true,
      img: null,
      audio: null,
      modalShow: false,
      timeOption: {
        time: true,
        timePattern: ['h', 'm', 's'],
      },
      frmChapter: {
        name: null,
        time: null,
      },
      editQuestion: false,
      modalShowLibraryQuestion: false,
      isLoading: false,
      isShow: true,
      isUploadImg: true,
      showQ: false,
      propQuestion: null,
      pageLength: 10,
      indexEditQuestion: null,
      editChapter: false,
      showAddSkill: false,
      frmLibraryQuestion: {
        name: '',
        tag_review: null,
        explain: null,
        tag: [],
        type: '',
        answers: [],
        status: false,
        is_correct: false,
        image: '',
        audio: '',
      },
      frmPart: {
        order: null,
        name: '',
        image: null,
        audio_type: null,
        audio_link: null,
        audio: null,
        url_fix_video: null,
        index: this.index,
        questions: [],
        document_type: null,
        document_id: null,
        document_link: null,
        document_file: null,
      },
      option: [{ label: 'Gán link', value: 'link' }, { label: 'Tải lên', value: 'upload' }],
      required,
      image,
      url,
      email,
      size,
      min_value,
    }
  },
  watch: {
    'frmPart.document_type': {
      handler(newVal) {
        if (newVal === 'link') {
          this.frmPart.document_file = null
        } else if (newVal === 'upload') {
          this.frmPart.document_link = null
        } else {
          this.frmPart.document_file = this.frmPart.document_link = null
        }
      },
    },
    'frmPart.audio_type': {
      handler(newVal) {
        if (newVal === 'upload') {
          this.frmPart.audio_link = null
        }
      },
    },
  },
  created() {
    // question
    if (this.item.questions) {
      if (typeof this.item.questions === 'object') {
        this.item.questions = Object.values(this.item.questions)
      }
      this.frmPart.questions = this.item.questions
    }
    // image
    if (this.item.image) {
      this.frmPart.image = this.item.image
    }
    // audio
    if (this.item.audio) {
      this.frmPart.audio = this.item.audio
      this.frmPart.audio_type = this.item.audio_type
      if (this.frmPart.audio_type === 'link') {
        this.frmPart.audio_link = this.item.audio.url
      }
    }
    // document
    if (this.item.document) {
      if (this.item.document_type === 'link') {
        this.frmPart.document_link = this.item.document.url
      } else {
        this.frmPart.document_id = this.item.document.id
        this.frmPart.document_file = this.item.document
      }
    }
    // detail part
    this.frmPart.name = this.item.name
    this.frmPart.url_fix_video = this.item.url_fix_video
    this.frmPart.document_type = this.item.document_type
  },
  methods: {
    editPart() {
      this.$refs.editPart.validate().then(success => {
        if (success) {
          this.modalShow = false
          this.$emit('editPart', this.frmPart)
        }
      })
    },
    editChapterAction(item) {
      this.frmChapter.name = item.name
      this.frmChapter.time = item.time
      this.editChapter = item.id
    },
    submitHandleChapter() {
      this.$refs.addChapter.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()

          formData.append('name', this.frmChapter.name)
          formData.append('time', this.frmChapter.time)
          formData.append('agent_id', this.item.id)
          formData.append('type', 1)

          if (!this.editChapter) {
            formData.append('order', this.chapterData.length + 1)
          }

          const url = this.editChapter ? `/chapters/${this.editChapter}` : '/chapters'
          if (this.editChapter) formData.append('_method', 'PUT')

          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.loadChapterByPart()
              this.frmChapter.name = this.frmChapter.time = null
              this.editChapter = false

              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    pushPart() {
      this.$emit('editPart', this.frmPart)
    },
    loadChapterByPart() {
      this.showChapter = true
      this.$http.get(`chapters/agent/${this.item.id}`, {
        params: {
          type: 1,
        },
      })
        .then(res => {
          this.chapterData = res.data.data
        }).catch()
        .finally(() => {
        })
    },
    deleteChapter(id) {
      this.$swal({
        title: this.$t('message.Confirm_delete'),
        text: false,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          if (typeof id === 'number') this.ids = [id]
          this.$http.delete(`/chapters/${id}`)
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.delete_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              this.loadChapterByPart(this.item.id)
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              this.$bvToast.toast(this.$t('message.delete_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    uploadMedia(media) {
      this.frmPart.image = media.image
      if (media.audio) {
        this.frmPart.audio = media.audio
      }
      if (media.audio_type) {
        this.frmPart.audio_type = media.audio_type
      }
      if (media.audio_link) {
        this.frmPart.audio_link = media.audio_link
      }
      if (media.document) {
        this.frmPart.document_id = media.document.id
        this.frmPart.document_file = media.document
      }
    },
    deleteSkill(index) {
      this.$emit('deleteSkill', index)
    },
    showPart() {
      this.isShow = !this.isShow
      this.showAddSkill = false
    },
    showQuestionGroup() {
      this.showQgr = !this.showQgr
    },
    pushQuestion(question) {
      if (this.indexEditQuestionChild || this.indexEditQuestionChild === 0) {
        this.frmPart.questions[this.indexEditQuestion].questions[this.indexEditQuestionChild] = question
      } else {
        this.frmPart.questions[this.indexEditQuestion] = question
      }
      this.modalShowAddQuestion = false
      this.pushPart()
    },
    deletePart(index) {
      this.$swal({
        title: 'Bạn có chắc chắn xóa không ?',
        text: 'Bạn sẽ không thể lấy lại dữ liệu đã xóa',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK !',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$emit('deletePart', index)
        }
      })
    },
    handleEdit(item) {
      this.modalShow = true
      this.frmPart.name = item.name
      this.frmPart.image = item.image
      this.frmPart.audio = item.audio
      this.frmPart.url_fix_video = item.url_fix_video
      this.frmPart.order = item.order
    },
    convertDate(iosDate) {
      if (!iosDate) return ''
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
    onEnd() {
      this.$emit('editPart', this.frmPart)
      this.$emit('enable', true)
    },
    addQuestion() {
      this.modalShowQuestion = true
    },
    deleteQuestion(index) {
      this.$swal({
        title: 'Bạn có chắc chắn xóa không ?',
        text: 'Bạn sẽ không thể lấy lại dữ liệu đã xóa',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK !',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.frmPart.questions.splice(index, 1)
        }
      })
    },
    listQuestionPart(listQuestions) {
      this.modalShowQuestion = false
      this.showQ = true
      listQuestions.map(q => {
        this.frmPart.questions.push(q)
        this.listQ.push(q.id)
      })
    },
    editLibraryQuestion(question) {
      this.modalShowLibraryQuestion = true
      this.frmLibraryQuestion.name = question.name
      this.frmLibraryQuestion.tag = question.tags
      this.frmLibraryQuestion.type = question.type
      this.frmLibraryQuestion.answers = question.answers
      this.frmLibraryQuestion.status = question.status
      this.frmLibraryQuestion.tag_review = question.tag_review_id
      this.frmLibraryQuestion.explain = question.explain
      this.frmLibraryQuestion.is_correct = question.is_correct
      this.frmLibraryQuestion.image = question.image ? question.image.url : null
      this.frmLibraryQuestion.audio = question.audio ? question.audio.url : null

      if (question.answers) {
        question.answers.map((v, index) => {
          if (v.is_correct === 1) {
            this.frmLibraryQuestion.is_correct = index
          }
        })
      }
    },
    handleEditQuestion(item, index, indexChild = false) {
      this.modalShowAddQuestion = true
      this.propQuestion = item
      this.indexEditQuestion = index
      this.indexEditQuestionChild = indexChild
    },
    showModalQuestionGroup() {
      this.modalShowQuestionGroup = true
      this.dataQuestionGroup.name = this.dataQuestionGroup.explain = null
      this.dataQuestionGroup.audio = null
      this.dataQuestionGroup.questions = []
      this.dataQuestionGroup.images = []
      this.qgrId = null
      this.titleQgr = 'Thêm câu hỏi nhóm'
    },
    // eslint-disable-next-line no-unused-vars
    handleEditQuestionGroup(qgr) {
      this.dataQuestionGroup.name = qgr.name
      this.dataQuestionGroup.images = qgr.images
      this.dataQuestionGroup.audio = qgr.audio
      this.dataQuestionGroup.questions = qgr.questions
      this.dataQuestionGroup.explain = qgr.explain
      this.qgrId = qgr.id
      this.modalShowQuestionGroup = true
      this.titleQgr = 'Chỉnh sửa câu hỏi nhóm'
    },
    questionGroup(qr) {
      this.modalShowQuestionGroup = false
      this.frmPart.questions.push(qr)
      if (qr.questions) {
        qr.questions.map(c => {
          this.listQ.push(c.id)
        })
      }
      this.frmPart.questions = [...new Map(this.frmPart.questions.map(item => [item.id, item])).values()]
    },

  },
}
</script>
<style lang="scss" scoped>
.part{
  padding: 10px 2px;
}
.point_question{
  margin-top: 4px;
  float: right;
  margin-right: 3px;
}
.modal-edit___BV_modal_content_{
  overflow: unset !important;
}
.btn-label {
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}
.library-document{
  justify-content: center;
  display: inline-flex;
  align-items: center;
  background: #fd7e1e;
  padding: 10px 10px;
  margin: 0 !important;
  font-size: 13px;
  border-radius: 8px;
  color: #fff;
  min-width: 120px;
}
.disabled {
  pointer-events:none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #f8f8f8;
  border-color: #d1dbe5;
}
.extend-review{
  width: 100%;
  padding: 12px;
  .card{
    border: 1px solid #d8d6de;
    box-shadow: none;
    border-radius: 6px;
  }
}
</style>
