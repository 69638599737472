<template>
  <b-overlay
    :show="isLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <validation-observer ref="addQuestionGroup">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              :name="$t('name_q')"
              vid="name"
              rules="required"
            >
              <label :class="errors.length > 0 ? 'text-danger': null">
                {{ $t('name_q') }} <span class="text-danger">(*)</span></label>
              <ckeditor
                v-model="frm.name"
                class="ckeditor"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-20 mb-1">
        <b-col cols="4">
          <label>{{ $t('b_q') }} <span class="text-danger">(*)</span></label>
        </b-col>
        <b-col cols="8">
          <feather-icon
            icon="PlusCircleIcon"
            size="25"
            class="float-right cursor-pointer"
            @click="addQuestion"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="4">
              <b-form-input
                v-model="filter.key"
                :placeholder="$t('search_key')"
              />
            </b-col>
            <b-col md="3">
              <b-form-select
                v-model="filter.type"
                :options="typeOptions"
                aria-placeholder="Loại câu hỏi"
              />
            </b-col>
            <b-col cols="5">
              <vue-autosuggest
                :suggestions="filteredOptions"
                :limit="30"
                :input-props="inputProps"
                @selected="onSelected"
                @input="onInputChange"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                </template>
              </vue-autosuggest>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        class="choose_question mt-1"
        @scroll="handleScroll"
      >
        <b-col
          cols="6"
          class="list-question"
          style="border-right: 0"
        >
          <b-list-group>
            <draggable
              :list="listQuestions"
              group="questions"
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="(listItem, index) in listQuestions"
                :key="index"
                class="question__item"
              >
                <div class="d-flex library-question justify-content-md-start align-items-center">
                  <feather-icon
                    class="cursor-pointer text-success"
                    icon="PlusIcon"
                    size="20"
                    @click="addQuestionGroup(listItem, index)"
                  />
                  <app-collapse class="detail-question">
                    <app-collapse-item :title="listItem.name">
                      <b-row class="align-items-stretch">
                        <b-col cols="12">
                          <ul class="list-preview-ans">
                            <li
                              v-for="(value, index2) in listItem.answers"
                              :key="index2"
                              class="item_quiz_create list-preview-ans--item"
                            >
                              <div class="list-preview-ans--heading">
                                <b-badge
                                  class="create-question-item "
                                  :variant=" value.is_correct ? 'light-success' : 'light-danger'"
                                >
                                  {{ answerIndex[index2] }}
                                </b-badge>
                                <div
                                  style="width: 76%"
                                  class="mb-0 title-ans ml-1"
                                  v-html="value.name"
                                />
                              </div>
                            </li>
                          </ul>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <b-col
                          v-if="listItem.image"
                          md="6"
                        >
                          <b-img
                            style="margin-left: 0px !important; max-width: 100%"
                            center
                            class="mb-1 mt-1"
                            height="200"
                            :src="listItem.image.path"
                          />

                        </b-col>
                        <b-col
                          v-if="listItem.audio "
                          md="6"
                        >
                          <audio
                            class="mt-2"
                            controls
                            style="width: 100%"
                          >
                            <source
                              :src="listItem.audio.path"
                              type="audio/ogg"
                            >
                          </audio>
                        </b-col>
                      </b-row>
                    </app-collapse-item>
                  </app-collapse>
                  <b-badge variant="light-primary">
                    {{ typeQuestion.find(val => val.type === listItem.type).text }}
                  </b-badge>
                </div>
              </b-list-group-item>
            </draggable>
          </b-list-group>
        </b-col>
        <b-col
          cols="6"
          class="list-question"
        >
          <b-list-group>
            <draggable
              :list="frm.questions"
              group="questions"
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="(listItem, index) in frm.questions"
                :key="index"
              >
                <div class="d-flex library-question justify-content-md-start align-items-center">
                  <feather-icon
                    class="cursor-pointer text-danger"
                    icon="CheckSquareIcon"
                    size="20"
                    @click="removeQuestionGroup(listItem,index)"
                  />
                  <app-collapse class="detail-question">
                    <app-collapse-item :title="listItem.name">
                      <b-row class="align-items-stretch">
                        <b-col cols="12">
                          <ul class="list-preview-ans">
                            <li
                              v-for="(value, index2) in listItem.answers"
                              :key="index2"
                              class="item_quiz_create list-preview-ans--item"
                            >
                              <div class="list-preview-ans--heading">
                                <b-badge
                                  class="create-question-item "
                                  :variant=" value.is_correct ? 'light-success' : 'light-danger'"
                                >
                                  {{ answerIndex[index2] }}
                                </b-badge>
                                <div
                                  style="width: 76%"
                                  class="mb-0 title-ans ml-1"
                                  v-html="value.name"
                                />
                              </div>
                            </li>
                          </ul>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <b-col
                          v-if="listItem.image"
                          md="6"
                        >
                          <b-img
                            style="margin-left: 0px !important; max-width: 100%"
                            center
                            class="mb-1 mt-1"
                            height="200"
                            :src="listItem.image.path"
                          />

                        </b-col>
                        <b-col
                          v-if="listItem.audio "
                          md="6"
                        >
                          <audio
                            class="mt-2"
                            controls
                          >
                            <source
                              :src="listItem.audio.path"
                              type="audio/ogg"
                            >
                          </audio>
                        </b-col>
                      </b-row>
                    </app-collapse-item>
                  </app-collapse>
                </div>
              </b-list-group-item>
            </draggable>
          </b-list-group>
          <small
            v-show="frm.questions.length < 1"
            class="text-danger"
          >{{ $t('choose_q') }}</small>

        </b-col>
      </b-row>
    </validation-observer>
    <upload-media
      class="btn_upload_custom"
      :image="frm.images"
      :audio="frm.audio"
      :choose="5"
      @uploadMedia="uploadMedia"
    />
    <b-row class="mt-2">
      <app-collapse class="extend-review">
        <app-collapse-item title="Mở rộng">
          <b-form-group label="Giải thích đáp án">
            <quill-editor
              v-model="frm.explain"
              :options="snowOption"
              max-rows="6"
            />
          </b-form-group>
        </app-collapse-item>
      </app-collapse>
    </b-row>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="danger"
      class="btn-sm float-right"
      @click="submitQuestionGroup"
    >
      <feather-icon
        icon="SaveIcon"
      />
      {{ $t('save_info') }}
    </b-button>
    <b-modal
      id="modal-edit"
      v-model="modalShow"
      :ok-title="$t('save_info')"
      ok-variant="danger"
      :cancel-title="$t('cancel')"
      centered
      size="lg"
      :title="$t('Add_question_new')"
      @ok.prevent="submit"
    >
      <validation-observer ref="addQuestion">
        <b-row>
          <b-col md="12">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="$t('name_q')"
                rules="required"
              >
                <label :class="errors.length > 0 ? 'text-danger': null">
                  {{ $t('name_q') }} <span class="text-danger">(*)</span></label>
                <quill-editor
                  v-model="frmQuestion.name"
                  :options="snowOption"
                  max-rows="6"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="'tag'"
                rules="required"
              >
                <label
                  :class="errors.length > 0 ? 'text-danger': null"
                >Tag <span class="text-danger">(*)</span></label>
                <v-select
                  id="tag"
                  v-model="frmQuestion.tag"
                  multiple
                  :reduce="label => label.id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="tagOptions"
                  taggable
                  :create-option="org => ({ id: org , name: org})"
                  push-tags
                  :state="errors.length > 0 ? false:null"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <label>{{ $t('ans') }} <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          :name="$t('ans')"
          vid="answers"
        >
          <div
            class="box_content_quiz"
            :class="errors.length > 0 ? 'box-content-danger': null"
          >
            <b-row class="align-items-stretch">
              <b-col cols="12">
                <!--                      <div class="quiz_content_left">-->
                <b-row>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('type')"
                      rules="required"
                    >
                      <b-form-group>
                        <v-select
                          id="type"
                          v-model="frmQuestion.type"
                          class=""
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="label"
                          :options="questionTypeData"
                          :reduce="label => label.value"
                          :state="errors.length > 0 ? false:null"
                          :clearable="false"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-list-group
                  v-if="frmQuestion.answers"
                  class="group_quiz_create"
                >
                  <b-list-group-item
                    v-for="(value, index2) in frmQuestion.answers"
                    :key="index2"
                    class="item_quiz_create"
                  >
                    <app-collapse class="custom_box_input">
                      <app-collapse-item title="">
                        <template slot="header">
                          <b-badge
                            variant="light-danger"
                            style="width: 5%;"
                          >
                            {{ answerIndex[index2] }}
                          </b-badge>
                          <div
                            style="width: 76%"
                            class="mb-0 title-ans ml-1"
                            v-html="value.name"
                          />
                          <b-form-checkbox
                            v-if="frmQuestion.type === 1"
                            v-model="frmQuestion.is_correct"
                            :value="index2"
                            :name="`radio-input-${value.id}`"
                          />
                          <feather-icon
                            class="btn_close_item"
                            icon="XIcon"
                            @click="removeAnswer(index2)"
                          />
                        </template>
                        <b-row>
                          <b-col cols="1" />
                          <b-col cols="9">
                            <template v-if="frmQuestion.type === 1">
                              <b-form-input
                                v-model="value.name"
                                type="text"
                              />
                            </template>
                            <template v-else-if="frmQuestion.type === 3">
                              <b-form-textarea
                                v-model="value.name"
                                placeholder="Nhập đáp án"
                                rows="3"
                                max-rows="6"
                                :state="errors.length > 0 ? false:null"
                              />
                            </template>
                          </b-col>
                        </b-row>
                      </app-collapse-item>
                    </app-collapse>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                class="btn-icon float-left mt-1 ml-1 button_create_ansewer"
                style=""
                @click="addAnswer()"
              >
                {{ $t('addNewAnswer') }}
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-row>
          </div>
          <small class="text-danger">{{ errors[0] }}</small>

        </validation-provider>
        <b-row class="mt-2">
          <app-collapse class="extend-review">
            <app-collapse-item title="Mở rộng">
              <b-form-group label="Tag đánh giá">
                <v-select
                  v-model="frmQuestion.tag_review"
                  :reduce="label => label.id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="tagOptionByReview"
                  taggable
                  :clearable="false"
                />
              </b-form-group>
              <b-form-group label="Giải thích đáp án">
                <quill-editor
                  v-model="frmQuestion.explain"
                  :options="snowOption"
                  max-rows="6"
                />
              </b-form-group>
            </app-collapse-item>
          </app-collapse>
        </b-row>
        <upload-media
          class="btn_upload_custom"
          :image="frmQuestion.image"
          :audio="frmQuestion.audio"
          @uploadMedia="uploadMediaQuestionChild"
        />
        </b-form>
      </validation-observer>
    </b-modal>

  </b-overlay>
</template>
<script>
import {
  required, image, url, email, size, min_value,
} from '@validations'
import config from '@/config'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { VueAutosuggest } from 'vue-autosuggest'

import draggable from 'vuedraggable'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import {
  VBModal,
  BRow,
  BCard,
  BFormSelect,
  BTab,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
  BOverlay,
  BListGroup,
  BListGroupItem,
  BFormFile,
  BPagination,
  BTabs,
  BFormTextarea,
  BEmbed,
  BImg,
  BBadge,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BCardText,
} from 'bootstrap-vue'
import Button from '@/views/components/button/Button'
import uploadMedia from '@/views/library/UploadMedia'
import { quillEditor } from 'vue-quill-editor'
import questionLibrary from './QuestionLibrary.vue'

export default {
  components: {
    Button,
    AppCollapse,
    AppCollapseItem,
    VueAutosuggest,
    quillEditor,
    BRow,
    uploadMedia,
    BCard,
    questionLibrary,
    BTab,
    ValidationProvider,
    BFormSelect,
    ValidationObserver,
    BCol,
    vSelect,
    draggable,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormCheckbox,
    BOverlay,
    BListGroup,
    BListGroupItem,
    BFormFile,
    BPagination,
    BTabs,
    BFormTextarea,
    BEmbed,
    BImg,
    BBadge,
    BFormRadio,
    BDropdown,
    BDropdownItem,
    BCardText,

  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    qgr: {
      type: Object,
      required: false,
    },
    qgrId: {
      required: false,
    },
    listQ: {
      required: false,
    },
  },
  data() {
    return {
      answerIndex: config.course.answerIndex,
      modalShow: false,
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: 'Tìm kiếm tag',
      },
      isLoading: false,
      typeQuestion: config.type_question,
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      required,
      image,
      url,
      email,
      size,
      min_value,
      filter: {
        key: '',
        type: '',
        tag: [],
      },
      tagOptions: [],
      questionTypeData: [
        {
          label: this.$t('multiple_c'),
          value: 1,
        },
        {
          label: this.$t('fill'),
          value: 3,
        },
      ],
      frm: {
        name: '',
        questions: [],
        images: [],
        audio: null,
        explain: null,
      },
      titleFile: '',
      typeOptions: [
        {
          text: 'Tất cả',
          value: 'all',
        },
        {
          text: 'Trắc nghiệm',
          value: 1,
        },
        {
          text: 'Ghi âm',
          value: 2,
        },
        {
          text: 'Ghép từ',
          value: 3,
        },
        {
          text: 'Ghép cột',
          value: 4,
        },
        {
          text: 'Điền từ',
          value: 5,
        },
      ],
      modalShowFile: false,
      currentPage: 1,
      total: 0,
      pageLength: 10,
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ size: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
          ],
        },
      },
      listQuestions: [],
      filterTag: '',
      loadMore: false,
      tagOptionByReview: [],
      frmQuestion: {
        name: '',
        type: null,
        tag_review: null,
        explain: null,
        answers: [],
        tag: [],
        is_correct: false,
        status: 1,
        image: null,
        audio: null,
      },

    }
  },
  watch: {
    'filter.key': {
      handler() {
        this.loadQuestions()
      },
    },
    'filter.tag': {
      handler() {
        this.loadQuestions()
      },
    },
    'filter.type': {
      handler() {
        this.loadQuestions()
      },
    },
  },
  created() {
    this.loadQuestions()
    if (this.qgr) {
      this.frm.audio = this.qgr.audio
      this.frm.explain = this.qgr.explain
      this.frm.name = this.qgr.name
      this.frm.images = this.qgr.images
      this.frm.questions = this.qgr.questions
    }
  },
  methods: {
    handleScroll() {
      const boxUserLog = document.querySelector('.choose_question')
      boxUserLog.addEventListener('scroll', () => {
        if (boxUserLog.offsetHeight + boxUserLog.scrollTop >= boxUserLog.scrollHeight - (boxUserLog.scrollHeight * 0.2) && !this.loadMore) {
          this.loadMore = true
          this.currentPage += 1
          this.loadQuestions(true)
        }
      })
    },
    loadTags: _.debounce(vm => {
      vm.isLoading = true
      vm.$http.get('/tags', {
        params: {
          key: vm.filterTag,
        },
      })
        .then(res => {
          const tagData = res.data.data
          tagData.map(v => {
            if (v.type === 0) {
              vm.tagOptions.push(v)
            } else {
              vm.tagOptionByReview.push(v)
            }
          })
          vm.filteredOptions = [{
            data: res.data.data,
          }]
        }).catch()
        .finally(() => {
          vm.isLoading = false
        })
    }, 350),
    onSelected(option) {
      this.filter.tag = []
      this.currentPage = 1
      const tag = option.item
      this.filter.tag.push(tag.id)
      this.loadQuestions()
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      this.filterTag = text
      this.loadTags(this)
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.loadQuestions()
    },
    loadQuestions(scroll = false) {
      if (this.filter.key === '' && this.filter.tag.length === 0 && this.filter.type === '') {
        this.listQuestions = []
      } else {
        this.isLoading = true
        this.$http.get('/questions', {
          params: {
            key: this.filter.key,
            tag: JSON.stringify(this.filter.tag),
            listQid: JSON.stringify(this.listQ),
            perPage: this.pageLength,
            page: this.currentPage,
          },
        })
          .then(res => {
            this.loadMore = false
            if (scroll) {
              const responses = res.data.data
              responses.map(v => {
                this.listQuestions.push(v)
              })
              this.loadMore = !responses.length
            } else {
              this.listQuestions = res.data.data
              this.total = res.data.total
              this.pageLength = res.data.per_page
            }
          }).catch()
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    addQuestionGroup(question, index) {
      this.frm.questions.push(question)
      this.listQuestions.splice(index, 1)
    },
    removeQuestionGroup(item, index) {
      this.frm.questions.splice(index, 1)
      this.listQuestions.push(item)
    },
    addAnswer() {
      this.frmQuestion.answers.push({
        name: '',
        is_correct: false,
      })
    },
    removeAnswer(index) {
      const ans = this.frmQuestion.answers
      ans.splice(index, 1)
    },
    addQuestion() {
      this.modalShow = true
    },
    submitQuestionGroup() {
      this.$refs.addQuestionGroup.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('name', this.frm.name)

          const childId = []
          if (this.frm.questions.length) {
            this.frm.questions.map(v => {
              childId.push(v.id)
            })
          }
          if (childId.length) {
            formData.append('child_id', JSON.stringify(childId))
          }

          if (this.frm.images.length) {
            formData.append('images', JSON.stringify(this.frm.images))
          }
          if (this.frm.audio) {
            formData.append('audio_id', this.frm.audio.id)
          }
          if (this.frm.explain) {
            formData.append('explain', this.frm.explain)
          }

          const url = this.qgrId ? `/question-groups/${this.qgrId}` : '/question-groups'
          if (this.qgrId) formData.append('_method', 'PUT')

          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$emit('questionGroup', res.data)
              this.frm.name = null
              this.frm.images = []
              this.frm.audio = this.frm.explain = null
              this.frm.questions = []
              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              let errorMsg = {}
              if (error.response.status === 422) {
                errorMsg = error.response.data.errors
              } else {
                // errorMsg.name = [error.response.data.error]
              }
              this.$refs.addQuestionGroup.setErrors(errorMsg)
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    uploadMedia(media) {
      this.frm.images = media.multipleImage
      this.frm.audio = media.audio
    },
    uploadMediaQuestionChild(media) {
      this.frmQuestion.image = media.image
      this.frmQuestion.audio = media.audio
    },
    submit() {
      this.$refs.addQuestion.validate().then(success => {
        if (success) {
          const formData = new FormData()

          if (this.frmQuestion.answers.length) {
            this.frmQuestion.answers = this.frmQuestion.answers.map((v, index) => {
              // eslint-disable-next-line no-param-reassign
              v.is_correct = false
              if (index === this.frmQuestion.is_correct || this.frmQuestion.type === 3) {
                v.is_correct = true
              }
              return v
            })
          }

          const tags = []
          const newTags = []
          if (this.frmQuestion.tag.length) {
            this.frmQuestion.tag.map(v => {
              v === parseInt(v) ? tags.push(v) : newTags.push({ name: v })
            })
            formData.append('tags', JSON.stringify(tags))
            formData.append('newTags', JSON.stringify(newTags))
          }

          if (this.frmQuestion.audio) {
            formData.append('audio_id', this.frmQuestion.audio.id)
          }
          if (this.frmQuestion.image) {
            formData.append('image_id', this.frmQuestion.image.id)
          }

          formData.append('questionParents', JSON.stringify(this.frmQuestion))
          formData.append('answers', JSON.stringify([]))

          const url = '/questions'

          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.modalShow = false
              this.frmQuestion.name = ''
              this.frmQuestion.type = null
              this.frmQuestion.answers = this.frmQuestion.tag = []
              this.frmQuestion.is_correct = false
              this.frm.questions.push(res.data)
              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              let errorMsg = {}
              if (error.response.status === 422) {
                errorMsg = error.response.data.errors
              } else {
                errorMsg.name = [error.response.data.error]
              }
              this.$refs.addQuestion.setErrors(errorMsg)
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },

  },
}
</script>
<style lang="scss" scoped>
.choose_question{
  max-height: 400px;
  overflow-y: scroll;
}
.choose-img{
  cursor: pointer !important;
}
.list-group-flush > .list-group-item:last-child{
  border-bottom-width:thin;
}
.extend-review{
  width: 100%;
  padding: 12px;
  .card{
    border: 1px solid #d8d6de;
    box-shadow: none;
    border-radius: 6px;
  }
}
.list-question{
  border: 1px dashed #d1d1d1;
}
.list-group{
  padding-top: 10px;
}
.detail-question{
  width: 100%;
  .card{
    box-shadow: none;
    background-color: transparent;
  }
}
</style>
