<template>
  <b-overlay
    :show="isLoading"
    :opacity="0.6"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <div style="text-align: left">
      <span style="font-weight: 600">Tổng điểm : {{ this.updatePoint }}</span> <br>
      <span
        v-if="passScore && passScore > updatePoint"
        style="color: #ff9f43"
      >Chú ý : Số điểm qua bài({{ this.passScore }} điểm) đang lớn hơn tổng điểm của bài ({{ this.updatePoint }} điểm)</span>
    </div>
    <div
      style="text-align: right"
      class="mb-1"
    >
      <button
        class="btn btn-danger btn-sm"
        style="display: inline-flex;align-items: center;"
        @click="showAddQuestionGroup = !showAddQuestionGroup"
      >
        <feather-icon
          icon="PlusCircleIcon"
          style=""
        />
        &nbsp;Thêm nhóm câu hỏi
      </button>
    </div>
    <div
      v-show="showAddQuestionGroup"
      class="box_content_quiz"
    >
      <b-row style="margin-top: 10px; margin-bottom: 30px;">
        <b-col
          md="12"
        >
          <validation-observer
            v-show="showAddQuestionGroup"
            ref="simpleRules"
          >
            <div class="form-label-group">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="tên nhóm câu hỏi"
                  vid="name"
                  rules="required"
                  mode="passive"
                >
                  <quill-editor
                    v-model="frm.name"
                    :options="snowOption"
                    :placeholder="'+ Thêm mới nhóm câu hỏi ...'"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
        >
          <upload-media
            class="btn_upload_custom"
            :image="frm.images"
            :audio="frm.audio"
            :choose="5"
            @uploadMedia="uploadMedia"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-button
            class="float-right btn btn-sm"
            variant="danger"
            :disabled="isLoading"
            style="margin: 10px 0 0 15px; min-width: 76px;"
            @click="validationForm"
          >
            Lưu
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col>
        <b-list-group class="list_group_custom list_group_custom-exercise">
          <draggable
            v-model="questionGroup"
            tag=""
            @end="value => onEnd(value)"
          >
            <b-list-group-item
              v-for="(item, index) in questionGroup"
              :key="index"
              class="align-items-center"
            >
              <question-group
                :item="item"
                :index-question-group="index"
                :total-point="updatePoint"
                :list-q="listQ"
                @update-total-point="updateTotalPoint"
                @deleteQuestionGroup="deleteQuestionGroup"
              />
            </b-list-group-item>
          </draggable>
        </b-list-group>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BListGroup, BCardText, BEmbed, BImg, BButton, BListGroupItem, BRow, BCol, BOverlay, BFormGroup, BFormFile,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { quillEditor } from 'vue-quill-editor'

import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'
import QuestionGroup from '@/views/capacity/exams/QuestionGroup'
import uploadMedia from '@/views/library/UploadMedia'

export default {
  components: {
    BListGroup,
    uploadMedia,
    QuestionGroup,
    BCardText,
    BListGroupItem,
    BButton,
    BImg,
    BFormFile,
    draggable,
    BEmbed,
    BRow,
    BCol,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // QuestionGroup,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    exerciseId: {
      type: Number,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    totalPoint: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    rows: {
      type: Array,
      required: true,
    },
    passScore: {
      type: Number,
      required: false,
    },
    listQ: {
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      frm: {
        name: null,
        images: [],
        audio_id: null,
      },
      updatePoint: this.totalPoint,
      imgUrl: null,
      audioUrl: null,
      // eslint-disable-next-line no-undef
      questionGroup: this.rows,
      snowOption: {
        theme: 'snow',
        placeholder: 'Nhập nhóm câu hỏi',
      },

      modalVideoShow: false,
      modalVideoName: null,
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      showAddQuestionGroup: false,
    }
  },
  watch: {
    rows: {
      handler(newVal, oldVal) {
        this.questionGroup = newVal
      },
    },
    totalPoint: {
      handler(newVal, oldVal) {
        this.updatePoint = newVal
      },
    },
  },
  methods: {
    uploadMedia(media) {
      this.frm.images = media.multipleImage
      this.frm.audio_id = media.audio ? media.audio.id : null
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()
          if (this.frm.name) formData.append('name', this.frm.name)
          if (this.exerciseId) formData.append('exercise_id', this.exerciseId)
          if (this.frm.images.length) formData.append('images', JSON.stringify(this.frm.images))
          if (this.frm.audio_id) formData.append('audio_id', this.frm.audio_id)

          this.$http({
            method: 'post',
            url: '/question-groups',
            data: formData,
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.showAddQuestionGroup = false
              this.showToast('success', 'Cập nhật thành công')

              const { data } = res
              data.newName = data.name
              data.edit = false
              data.toggle = true
              this.rows.push(data)
              this.frm.name = null
              // eslint-disable-next-line no-multi-assign
              this.frm.audio_id = null
              // eslint-disable-next-line no-multi-assign
              this.frm.images = []
            })
          // eslint-disable-next-line no-unused-vars
            .catch(e => {
              this.showToast('danger', 'Cập nhật thất bại')
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    updateTotalPoint(item) {
      this.updatePoint = item
    },
    onEnd(value) {
      if (value.oldIndex === value.newIndex) return
      const newOrder = this.questionGroup[value.newIndex].order
      const oldOrder = this.questionGroup[value.oldIndex].order
      if (newOrder === oldOrder) return
      if (value.newIndex < value.oldIndex) {
        this.changeOrder(newOrder, this.questionGroup[value.newIndex + 1].order)
      } else {
        this.changeOrder(newOrder, this.questionGroup[value.newIndex - 1].order)
      }
    },
    changeOrder(oldOrder, newOrder) {
      this.isLoading = true
      this.$http.put('/question-groups', { oldOrder, newOrder, exercise_id: this.exerciseId })
      // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.showToast('success', 'Cập nhật thứ tự thành công')
          // eslint-disable-next-line no-unused-vars
        }).catch(e => {
          this.showToast('danger', 'Cập nhật thứ tự thất bại')
        }).finally(() => {
          this.isLoading = false
        })
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Thông báo',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    deleteQuestionGroup(index) {
      this.rows.splice(index, 1)
      this.$emit('update-total-point', true)
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-file > label.custom-file-label{
  min-height: auto !important;
}
.full-image{
  width: 100%;
  height: 100%;
}
.full-audio{
  width: 100%;
}
.default-image{
  width: 100%;
  object-fit: cover;
  height: 200px;
}
</style>
